import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Form, FormGroup, Label, Input, Row } from 'reactstrap';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import validateRut from 'helpers/rutFormat';
import { objectsToOptions } from '../../helpers/selects';
import isUserHasProfile from '../../helpers/profileValidator';
import { USER_WRITE } from '../../helpers/profilePermission';

function handleNull(value) {
  return value !== undefined && value !== null ? value : '';
}

class CreateUserForm extends Component {
  constructor(props) {
    super(props);
    const { userData } = this.props;

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleRut = this.handleRut.bind(this);
    this.state = {
      rut: userData ? handleNull(userData.rut) : '',
      phone: userData ? handleNull(userData.phone) : '',
      invalidRut: false,
      profiles: [],
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.userData) {
      this.setState({
        rut: handleNull(nextProps.userData.rut),
        phone: handleNull(nextProps.userData.phone),
        profiles: handleNull(nextProps.userData.profiles),
      });
    }
  }

  isDisabledByProfile = () => {
    const validRole = isUserHasProfile(USER_WRITE);
    return !validRole;
  };

  handleProfilesChange = (profilesOpts) => {
    this.setState({
      profiles: (profilesOpts && profilesOpts.map((opt) => opt.object)) || [],
    });
  };

  handleRut(e) {
    e.preventDefault();
    const rut = e.target.value.replace(/[A-J]*[L-Z]*[a-j]*[l-z]*/g, '');
    const formatRut = validateRut(rut);
    this.setState({
      rut: rut.length > 0 ? formatRut.formatRut : rut,
      invalidRut: !formatRut.isValid,
    });
  }

  handleChange(e) {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleSubmit() {
    const { onSubmit, userData } = this.props;
    const { rut, phone, profiles } = this.state;
    onSubmit({
      rut,
      phone,
      job_title: userData ? userData.job_title : '',
      organization_id: userData.organization_id,
      username: userData.username,
      email: userData ? userData.email : '',
      first_name: userData ? userData.first_name : '',
      last_name: userData ? userData.last_name : '',
      is_active: userData.is_active,
      profiles_set: profiles.map((profile) => profile.id),
    });
  }

  render() {
    const { userData, allProfiles } = this.props;
    const { rut, phone, invalidRut, profiles } = this.state;
    const allProfilesOptions = allProfiles ? objectsToOptions(allProfiles, 'nombre', true) : [];
    const profilesOptions = profiles ? objectsToOptions(profiles, 'nombre', true) : [];
    return (
      <div>
        <div>
          <div className="form-box form-group">
            <p className="form-title">Información empleado</p>
            <FormGroup className="row">
              <Label className="col-sm-2 col-form-label" for="rut">
                RUT
              </Label>
              <Col md={10}>
                <Input
                  name="rut"
                  id="rut"
                  onChange={this.handleRut}
                  disabled={this.isDisabledByProfile()}
                  value={rut}
                  placeholder="Ingrese RUT"
                  invalid={rut.length > 0 && rut.length > 3 && invalidRut}
                />
              </Col>
            </FormGroup>
            <FormGroup className="row">
              <Label className="col-sm-2 col-form-label" for="name">
                Nombre
                <span className="required text-danger">*</span>
              </Label>
              <Col md={10}>
                <Input
                  readOnly
                  type="name"
                  name="name"
                  id="name"
                  value={userData ? userData.first_name : ''}
                />
              </Col>
            </FormGroup>
            <FormGroup className="row">
              <Label className="col-sm-2 col-form-label" for="lastNames">
                Apellidos
                <span className="required text-danger">*</span>
              </Label>
              <Col md={10}>
                <Input
                  readOnly
                  type="lastNames"
                  name="lastNames"
                  id="lastNames"
                  value={userData ? userData.last_name : ''}
                />
              </Col>
            </FormGroup>
            <FormGroup className="row">
              <Label className="col-sm-2 col-form-label" for="position">
                Cargo
                <span className="required text-danger">*</span>
              </Label>
              <Col md={10}>
                <Input
                  readOnly
                  type="position"
                  name="position"
                  id="position"
                  value={userData ? handleNull(userData.job_title) : ''}
                />
              </Col>
            </FormGroup>
            <FormGroup className="row">
              <Label className="col-sm-2 col-form-label" for="perfiles">
                Perfiles
              </Label>
              <Col md={10}>
                <Select
                  id="perfiles"
                  name="perfiles"
                  clearable={false}
                  removeSelected={false}
                  placeholder="Seleccione perfiles"
                  options={allProfilesOptions}
                  onChange={this.handleProfilesChange}
                  isDisabled={this.isDisabledByProfile()}
                  value={profilesOptions}
                  isMulti
                />
              </Col>
            </FormGroup>
            <FormGroup className="row">
              <Label className="col-sm-2 col-form-label" for="phone">
                Teléfono
              </Label>
              <Col md={4}>
                <Input
                  type="phone"
                  name="phone"
                  id="phone"
                  value={phone}
                  onChange={this.handleChange}
                  disabled={this.isDisabledByProfile()}
                  placeholder="Ingrese teléfono"
                />
              </Col>
            </FormGroup>
          </div>
          <Form className="form-box form-group">
            <p className="form-title">Información de usuario</p>
            <FormGroup className="row">
              <Label className="col-sm-2 col-form-label" for="email">
                Dirección de correo
                <span className="required text-danger">*</span>
              </Label>
              <Col md={10}>
                <Input readOnly name="email" id="email" value={userData ? userData.email : ''} />
              </Col>
            </FormGroup>
            {!this.isDisabledByProfile() && (
              <Row>
                <div className="col-md-2">
                  <Button
                    style={{
                      marginTop: '33px',
                      width: '100%',
                      backgroundColor: '#F98724',
                      border: '#F98724',
                    }}
                    onClick={this.handleSubmit}
                  >
                    <FontAwesomeIcon icon={faSave} />
                    Guardar
                  </Button>
                </div>
              </Row>
            )}
          </Form>
        </div>
      </div>
    );
  }
}

CreateUserForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  userData: PropTypes.shape({
    email: PropTypes.string,
    first_name: PropTypes.string,
    job_title: PropTypes.string,
    last_name: PropTypes.string,
    organization_id: PropTypes.number,
    phone: PropTypes.string,
    rut: PropTypes.string,
    username: PropTypes.string,
    profiles: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        slug: PropTypes.string,
        nombre: PropTypes.string,
      }),
    ),
  }),
  allProfiles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      slug: PropTypes.string,
      nombre: PropTypes.string,
    }),
  ).isRequired,
};

CreateUserForm.defaultProps = {
  userData: {},
};

export default CreateUserForm;
