import { PROFILES } from 'CONST';

export const USER_WRITE = [PROFILES.ceo, PROFILES.administrador];
export const USER_READ = [PROFILES.ceo, PROFILES.administrador];

export const CLIENT_WRITE = [
  PROFILES.ceo,
  PROFILES.consultaEspecial,
  PROFILES.comercialGarantia,
  PROFILES.comercialCredito,
  PROFILES.gerenteOperaciones,
];

export const CLIENT_READ = [
  PROFILES.ceo,
  PROFILES.consultaEspecial,
  PROFILES.comercialGarantia,
  PROFILES.riesgoGarantia,
  PROFILES.gerenteGarantia,
  PROFILES.suscripcionGarantia,
  PROFILES.comercialCredito,
  PROFILES.gerenteRiesgoGarantia,
  PROFILES.analistaRiesgoCredito,
  PROFILES.fiscalia,
  PROFILES.actuariado,
  PROFILES.gerenteOperaciones,
  PROFILES.analistaOperaciones,
  PROFILES.riesgoOperacional,
  PROFILES.siniestros,
  PROFILES.recuperoSiniestros,
  PROFILES.consultaBasico,
];
