import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

import { cellOperation, cellOperationManyDesc, headerOperation } from '../../helpers/tables';
import BaseTable from '../UI/BaseTable';
import FilterSelect from '../shared/FilterSelect';

const NO_FILTERED_ID = -1;

const ClientListTable = ({
  count,
  data,
  loading,
  pages,
  onFetchData,
  onFilteredChange,
  roles
}) => {
  const columns = [
    {
      Header: headerOperation('RUT'),
      id: 'rut',
      accessor: i =>
        cellOperation(
          <Link className="table-link" to={`/clients/edit/${i.id}`}>
            {i.rut}
          </Link>,
        ),
      width: 130,
    },
    {
      Header: headerOperation('Nombre'),
      id: 'search',
      accessor: i => {
        if (i.type_id === 1) {
          return cellOperation(`${i.first_name} ${i.last_name}`);
        }
        return cellOperation(i.nombre_fantasia);
      },
      minWidth: 180,
    },
    {
      Header: headerOperation('Roles'),
      id: 'roles__id',
      accessor: i => cellOperationManyDesc(i.roles),
      Filter: (obj) => (
        <FilterSelect
          onFilterChange={obj.onChange}
          filter={obj.filter}
          options={
            roles.map((elem) => (
              { label: elem.label, value: elem.value.id }
            ))
          }
          defaultOption={{ label: 'Todos', value: NO_FILTERED_ID }}
        />
      ),
      minWidth: 150,
    },
    {
      Header: headerOperation('Comuna'),
      id: 'comuna__name__icontains',
      accessor: i => cellOperation(i.comuna),
      minWidth: 180,
    },
    {
      Header: headerOperation('Dirección'),
      id: 'address',
      accessor: i => cellOperation(i.address),
      filterable: false,
      minWidth: 300,
    },
  ];
  return (
    <div style={{ marginTop: '30px' }}>
      <BaseTable
        filterable
        resizable={false}
        loading={loading}
        data={data}
        columns={columns}
        showPagination
        resultCount={count}
        pages={pages}
        onFetchData={onFetchData}
        onFilteredChange={onFilteredChange}
      />
    </div>
  );
};

ClientListTable.propTypes = {
  count: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool.isRequired,
  pages: PropTypes.number.isRequired,
  onFetchData: PropTypes.func.isRequired,
  onFilteredChange: PropTypes.func.isRequired,
  roles: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ClientListTable;
