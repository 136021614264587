import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PageContainer from 'components/PageContainer';
import { getData, patchData, cleanUser, cleanClient } from 'redux-store/actions';
import {
  GET_USER_DETAIL,
  SAVE_USER,
  USERS_GET_ALL_PROFILES,
  GET_CLIENT_LIST_SELECT,
  GET_CLIENT_DETAIL,
} from 'redux-store/constants/action-types';
import { API, BASE_URL, ROLES } from 'CONST';
import CreateUserForm from 'components/CreateUserForm';

const api = API.persons;

function mapDispatchToProps(dispatch) {
  return {
    getData: user => dispatch(getData(user)),
    cleanUser: user => dispatch(cleanUser(user)),
    patchData: data => dispatch(patchData(data)),
    cleanClient: client => dispatch(cleanClient(client)),
  };
}

function getForeignOptions(values) {
  return values.filter(value => ['externo-proyecto', 'externo-organizacion'].includes(value.slug))
}

class ClientUserEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: null,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { getData: getUserDetail, match } = this.props;
    const { id } = match.params;
    getUserDetail({
      url: `${BASE_URL}${api}${id}/`,
      name: 'users',
      type: GET_USER_DETAIL,
    });
    getUserDetail({
      url: `${BASE_URL}${API.perfiles}`,
      type: USERS_GET_ALL_PROFILES,
    });
    getUserDetail({
      url: `${BASE_URL}${API.clients}?roles__natural_key=${ROLES.afianzadoVentaVerde}`,
      name: 'clients',
      type: GET_CLIENT_LIST_SELECT,
    });
  }

  componentWillReceiveProps(nextProps) {
    const { getData: getUserDetail, cleanUser: resetStoreUser, history, clients } = this.props;
    const { userData } = this.state;
    if (nextProps.users.userDetail) {
      if (!userData && !clients.clientDetail) {
        // Se obtiene la información del cliente al que pertenece el usuario.
        getUserDetail({
          url: `${BASE_URL}${API.clients}${nextProps.users.userDetail.organization_id}/`,
          name: 'clients',
          type: GET_CLIENT_DETAIL,
        });
      }
      this.setState({
        userData: nextProps.users.userDetail,
      });
    }
    if (nextProps.users.successUser) {
      resetStoreUser();
      history.push('/client-users');
    }
  }

  componentWillUnmount() {
    const { cleanUser: resetStoreUser, cleanClient: resetStoreClient } = this.props;
    resetStoreUser();
    resetStoreClient();
  }

  handleSubmit(values) {
    const { match, patchData: saveUser } = this.props;
    const { id } = match.params;
    saveUser({
      url: `${BASE_URL}${api}${id}/`,
      type: SAVE_USER,
      config: values,
    });
  }

  render() {
    const { userData } = this.state;
    const { users, clients } = this.props;
    return (
      <PageContainer
        breadcrumbs={[
          { name: 'CLIENTES', url: '/client-users' },
          { name: 'EDITAR USUARIO' },
        ]}
      >
        <CreateUserForm
          onSubmit={this.handleSubmit}
          userData={userData}
          allProfiles={getForeignOptions(users.profiles)}
          organizations={clients.clientList}
          edit
          isOrsan={clients.clientDetail && clients.clientDetail.is_main}
        />
      </PageContainer>
    );
  }
}

const mapStateToProps = state => {
  return {
    users: state.users,
    clients: state.clients,
  };
};

ClientUserEdit.propTypes = {
  cleanUser: PropTypes.func.isRequired,
  cleanClient: PropTypes.func.isRequired,
  getData: PropTypes.func.isRequired,
  history: PropTypes.shape({
    length: PropTypes.number,
  }).isRequired,
  match: PropTypes.shape({
    path: PropTypes.string
  }).isRequired,
  patchData: PropTypes.func.isRequired,
  clients: PropTypes.shape({}).isRequired,
  users: PropTypes.shape({
    list: PropTypes.array,
    listData: PropTypes.object,
    successUser: PropTypes.bool,
    userDetail: PropTypes.object,
  }).isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ClientUserEdit);
