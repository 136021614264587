import React, { Component } from 'react';
import PropTypes from 'prop-types';
import connect from 'react-redux/es/connect/connect';

import BaseTable from 'components/UI/BaseTable';
import * as actions from 'redux-store/actions';
import { API, BASE_URL } from 'CONST';
import { GET_USER_BY_CLIENT } from 'redux-store/constants/action-types';

function mapDispatchToProps(dispatch) {
  return {
    getData: clients => dispatch(actions.getData(clients)),
    cleanUserList: users => dispatch(actions.cleanUserList(users)),
  };
}

class ClientUsersTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
    this.fetchData = this.fetchData.bind(this);
  }

  fetchData(state) {
    const { client, getData: getUsers } = this.props;

    if (client.value) {
      this.setState({ loading: true });

      const orgId = client ? client.value : client;
      let filters = `${BASE_URL}${
        API.persons
      }?organization_id=${orgId}&page=${state.page + 1}&`;

      if (state.filtered.length) {
        state.filtered.forEach(node => {
          filters =
            node.value.length > 2 ? `${filters}${node.id}=${node.value}&` : '';
        });
      }

      getUsers({
        url: filters,
        type: GET_USER_BY_CLIENT,
      });
      this.setState({ loading: false });
    }
  }

  render() {
    const { columns, count, data, pages } = this.props;
    const { loading } = this.state;
    return (
      <div style={{ marginTop: '30px' }}>
        <BaseTable
          filterable
          resizable={false}
          loading={loading}
          data={data}
          columns={columns}
          showPagination
          resultCount={count}
          pages={pages}
          onFetchData={this.fetchData}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  users: state.users,
});

const ClientUsersTableView = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ClientUsersTable);

ClientUsersTable.propTypes = {
  client: PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string,
  }),
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  count: PropTypes.number,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  getData: PropTypes.func.isRequired,
  pages: PropTypes.number.isRequired,
};

ClientUsersTable.defaultProps = {
  count: 0,
  client: {value: null, label: null},
};

export default ClientUsersTableView;
