import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'reactstrap';
import { cellOperation, headerOperation } from 'helpers/tables';
import BaseTable from './BaseTable';

const AliasTable = ({ data, handleDelete }) => {
  const columns = [
    {
      Header: headerOperation('Alias'),
      id: 'alias',
      accessor: 'alias',
      Cell: (row) => cellOperation(row.value),
      sortable: false,
    },
    {
      Header: headerOperation('Comuna'),
      id: 'comunaLabel',
      accessor: 'comunaLabel',
      Cell: (row) => cellOperation(row.value),
      sortable: false,
    },
    {
      Header: headerOperation('Dirección'),
      id: 'address',
      accessor: 'address',
      Cell: (row) => cellOperation(row.value),
      sortable: false,
    },
    {
      Header: headerOperation('Acciones'),
      id: 'can_delete',
      accessor: 'can_delete',
      Cell: (row) => cellOperation( row.value ? 
        (
          <Button className="table-link" onClick={e => handleDelete(e, row.original)}>
            <FontAwesomeIcon icon={faTrashAlt} id={row.original.alias} />
          </Button>
        ) : ('-')
      ),
      sortable: false,
    },
  ];
  return (
    <div>
      <BaseTable
        className="alias-table"
        columns={columns}
        data={data}
        resizable={false}
      />
    </div>
  );
};

AliasTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  handleDelete: PropTypes.func.isRequired,
};

AliasTable.defaultProps = {
  data: [],
};

export default AliasTable;
