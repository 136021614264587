import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { Row, Button, Col, FormGroup, Label, Input, FormFeedback } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import validateRut from 'helpers/rutFormat';
import { get } from 'helpers/requests';
import { API, BASE_URL, PROFILES } from 'CONST';
import { emailValidate } from 'helpers/helpers';
import { objectsToOptions, objectToOption } from '../helpers/selects';

const CreateUserForm = ({ userData, allProfiles, organizations, onSubmit, edit, isOrsan }) => {
  const [rut, setRut] = useState('');
  const [isValidRut, setIsValidRut] = useState(false);
  const [email, setEmail] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [cargo, setCargo] = useState('');
  const [isCoordinator, setIsCoordinator] = useState(false);
  const [telefono, setTelefono] = useState('');

  const [profile, setProfile] = useState(null);
  const [profilesOptions, setProfilesOptions] = useState([]);

  const [organization, setOrganization] = useState(null);
  const [organizationsOptions, setOrganizationsOptions] = useState([]);

  const [projects, setProjects] = useState([]);
  const [projectsOptions, setProjectsOptions] = useState([]);
  const [hasProjectsOptions, setHasProjectsOptions] = useState(false);

  const [organizationId, setOrganizationId] = useState('');
  const [username, setUsername] = useState('');
  const [isActive, setIsActive] = useState(true);

  const [isDisabled, setIsDisabled] = useState(true);

  const getOption = (options, id) => {
    return objectsToOptions(
      options.filter(option => option.value === id || option.value.id === id),
      'label',
    )[0]
  }

  const setRutValidation = (data) => {
    const rutData = data.replace(/[A-J]*[L-Z]*[a-j]*[l-z]*/g, '');
    const formatRut = validateRut(rutData);
    setRut(rutData.length > 0 ? formatRut.formatRut : rutData);
    setIsValidRut(formatRut.isValid)
  }

  const setEmailValidation = (data) => {
    setEmail(data);
    setIsValidEmail(emailValidate(data));
  }

  const setDataEdition = (data) => {
    setRutValidation(data.rut);
    setEmail(data.email);
    setIsValidEmail(emailValidate(data.email));
    setFirstName(data.first_name);
    setLastName(data.last_name);
    setCargo(data.job_title);
    setIsCoordinator(data.is_coordinator);
    setTelefono(data.phone);
    setOrganizationId(data.organization_id);
    setUsername(data.username);
    setIsActive(data.is_active);

    if (data.profiles.length !== 0) {
      setProfile(objectToOption(data.profiles[0], 'nombre'));
    }

    if (organizations && data.projects_organization_id) {
      setOrganization(getOption(organizations, data.projects_organization_id));
    }

    if (hasProjectsOptions && data.projects_ids.length !== 0) {
      setProjects(data.projects_ids.map(project => getOption(projectsOptions, project).value));
    }
  }

  const setOrganizationData = (data) => {
    setOrganization(data);
    setProjects([]);
  }

  const getProjects = () => {
    if (profile && profile.value && profile.value.slug === PROFILES.externoProyecto) {
      return projects ? projects.map(project => project.value.id) : [];
    }
    if (profile && profile.value && profile.value.slug === PROFILES.externoOrganizacion) {
      return []
    }
    return []
  }

  const handleSubmit = () => {
    const data = {
      rut,
      email,
      phone: telefono,
      is_coordinator: isCoordinator,
      job_title: cargo,
      organization_id: organizationId,
      first_name: firstName,
      last_name: lastName,
      is_active: isActive,
      profiles_set: profile && profile.value ? [profile.value.id] : [],
      projects_organization_id: organization && organization.value && organization.value.value,
      projects_ids: getProjects(),
    }

    if (edit){
      data.username = username
    }

    onSubmit(data);
  }

  useEffect(() => {
    setIsDisabled(!(isValidRut && firstName && lastName && cargo));
  }, [isValidRut, firstName, lastName, cargo]);

  useEffect(() => {
    if (organization && organization.value) {
      get(`${BASE_URL}${API.projects}?organizacion_id=${organization.value.value}`).then((response) => {
        setProjectsOptions(objectsToOptions(response, 'nombre'));
        setHasProjectsOptions(true);
      });
    }
  }, [organization]);

  useEffect(() => {
    if (userData && Object.keys(userData).length !== 0) {setDataEdition(userData)}
    if (allProfiles) {setProfilesOptions(objectsToOptions(allProfiles, 'nombre'))}
    if (organizations) {setOrganizationsOptions(objectsToOptions(organizations, 'label'))}
  }, [userData, allProfiles, organizations, hasProjectsOptions]);


  const onChangeProfile = (event) => {
    setProfile(event);
    setOrganization(null);
    setProjects([]);
  }

  return (
    <div>
      <div className="form-box form-group">
        <Col md="8">
          <p className="form-title">Información empleado</p>
          <FormGroup className="row">
            <Label className="col-sm-2 col-form-label" for="email">
              Dirección de correo
              <span className="required text-danger">*</span>
            </Label>
            <Col md={9}>
              <Input
                readOnly={edit}
                name="email"
                id="email"
                onChange={(event) => setEmailValidation(event.target.value)}
                value={email}
                invalid={!!email && email.length > 2 && !isValidEmail}
              />
              <FormFeedback style={{ fontSize: '12px', margin: '0 0 5px 5px' }}>
                {!isValidEmail ? 'Email inválido.' : ''}
              </FormFeedback>
            </Col>
          </FormGroup>
          <FormGroup className="row">
            <Label className="col-sm-2 col-form-label" for="rut">
              RUT
              <span className="required text-danger">*</span>
            </Label>
            <Col md={9}>
              <Input
                name="rut"
                id="rut"
                onChange={(event) => setRutValidation(event.target.value)}
                value={rut}
                invalid={!!rut && rut.length > 3 && !isValidRut}
              />
              <FormFeedback style={{ fontSize: '12px', margin: '0 0 5px 5px' }}>
                {rut && rut.length > 3 && !isValidRut ? 'Rut inválido.' : ''}
              </FormFeedback>
            </Col>
          </FormGroup>
          <FormGroup className="row">
            <Label className="col-sm-2 col-form-label" for="name">
              Nombre
              <span className="required text-danger">*</span>
            </Label>
            <Col md={9}>
              <Input
                type="name"
                name="firstName"
                id="first_name"
                onChange={(event) => setFirstName(event.target.value)}
                value={firstName}
              />
            </Col>
          </FormGroup>
          <FormGroup className="row">
            <Label className="col-sm-2 col-form-label" for="lastNames">
              Apellidos
              <span className="required text-danger">*</span>
            </Label>
            <Col md={9}>
              <Input
                type="lastNames"
                name="lastName"
                id="last_name"
                onChange={(event) => setLastName(event.target.value)}
                value={lastName}
              />
            </Col>
          </FormGroup>
          <FormGroup className="row">
            <Label className="col-sm-2 col-form-label" for="position">
              Cargo
              <span className="required text-danger">*</span>
            </Label>
            <Col md={9}>
              <Input
                type="position"
                name="jobTitle"
                id="job_title"
                onChange={(event) => setCargo(event.target.value)}
                value={cargo}
              />
            </Col>
          </FormGroup>
          <FormGroup className="row">
            <Label className="col-sm-2 col-form-label" for="is-coordinator">
              Es coordinador
            </Label>
            <Col md={9}>
              <Input
                id="is-coordinator"
                name="isCoordinator"
                type="checkbox"
                checked={isCoordinator}
                onChange={(event) => setIsCoordinator(event.target.checked)}
                style={{ marginLeft: 0 }}
              />
            </Col>
          </FormGroup>
          <FormGroup className="row">
            <Label className="col-sm-2 col-form-label" for="phone">
              Teléfono
            </Label>
            <Col md={9}>
              <Input
                type="phone"
                name="phone"
                id="phone"
                value={telefono}
                onChange={(event) => setTelefono(event.target.value)}
                placeholder="Ingrese teléfono"
              />
            </Col>
          </FormGroup>
        </Col>
      </div>
      {!isOrsan && (
        <div className="form-box">
          <Col md="8">
            <p className="form-title">Asignación a proyectos (Opcional)</p>
            <FormGroup className="row">
              <Label className="col-sm-2 col-form-label" for="perfiles">
                Perfil
              </Label>
              <Col md={10}>
                <Select
                  id="perfil"
                  name="perfil"
                  isClearable
                  removeSelected={false}
                  placeholder="Seleccione un perfil"
                  options={profilesOptions}
                  onChange={(event) => onChangeProfile(event)}
                  value={profile}
                />
              </Col>
            </FormGroup>
            { profile &&
              (
                <>
                  <FormGroup className="row">
                    <Label className="col-sm-2 col-form-label" for="organizacion">
                      Organización
                    </Label>
                    <Col md={10}>
                      <Select
                        id="organizacion"
                        name="organizacion"
                        clearable={false}
                        removeSelected={false}
                        placeholder="Seleccione una organización"
                        options={organizationsOptions}
                        onChange={(event) => setOrganizationData(event)}
                        value={organization}
                      />
                    </Col>
                  </FormGroup>
                  { profile && profile.value && profile.value.slug === PROFILES.externoProyecto &&
                    (
                      <FormGroup className="row">
                        <Label className="col-sm-2 col-form-label" for="proyecto">
                          Proyecto
                        </Label>
                        <Col md={10}>
                          <Select
                            id="proyecto"
                            name="proyecto"
                            clearable={false}
                            removeSelected={false}
                            placeholder="Seleccione al menos un proyecto"
                            options={projectsOptions}
                            onChange={(event) => setProjects(event)}
                            value={projects}
                            isMulti
                            getOptionValue={project => project.value.id}
                          />
                        </Col>
                      </FormGroup>
                    )
                  }
                </>
              )
            }
          </Col>
        </div>
      )}
      <div className="form-box">
        <Col md="8">
          <Row>
            <div className="col-md-4">
              <Button
                style={{
                  width: '100%',
                  backgroundColor: '#F98724',
                  border: '#F98724',
                }}
                disabled={isDisabled}
                onClick={handleSubmit}
              >
                <FontAwesomeIcon icon={faSave} />
                Guardar
              </Button>
            </div>
          </Row>
        </Col>
      </div>
    </div>
  );
}

CreateUserForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  edit: PropTypes.bool,
  userData: PropTypes.shape({
    email: PropTypes.string,
    first_name: PropTypes.string,
    id: PropTypes.number,
    is_active: PropTypes.bool,
    job_title: PropTypes.string,
    last_name: PropTypes.string,
    organization_id: PropTypes.number,
    projects_organization_id: PropTypes.number,
    phone: PropTypes.string,
    rut: PropTypes.string,
    username: PropTypes.string,
    is_coordinator: PropTypes.bool,
    projects_ids: PropTypes.arrayOf(PropTypes.number),
    profiles: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        slug: PropTypes.string,
        nombre: PropTypes.string,
      }),
    ),
  }),
  allProfiles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      slug: PropTypes.string,
      nombre: PropTypes.string,
    }),
  ).isRequired,
  organizations: PropTypes.arrayOf(
    PropTypes.shape({
      nombre_fantasia: PropTypes.string,
    }),
  ),
  isOrsan: PropTypes.bool,
}

CreateUserForm.defaultProps = {
  userData: {},
  organizations: [],
  edit: false,
  isOrsan: true,
};

export default CreateUserForm;
