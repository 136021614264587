import React, { Component } from 'react';
import {
  Button,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';

class SearchUserFromAD extends Component {
  constructor(props) {
    super(props);
    this.getPersonByUsername = this.getPersonByUsername.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      username: '',
    };
  }

  getPersonByUsername() {
    const { userExists } = this.props;
    const { username } = this.state;
    userExists(username.toLowerCase());
  }

  handleChange(e) {
    this.setState({
      username: e.target.value,
    });
  }

  render() {
    const { username } = this.state;
    const { loading } = this.props;
    return (
      <div>
        <div className="form-box">
          <FormGroup>
            <p style={{ fontSize: '18px', marginBottom: '15px' }}>
              Cargar datos de usuario por Username desde Active Directory
            </p>
            <InputGroup>
              <Input
                onChange={this.handleChange}
                placeholder="Ingrese Username"
                value={username}
              />
              <InputGroupAddon addonType="append">
                <Button
                  style={{
                    backgroundColor: '#F98724',
                    border: '#F98724',
                  }}
                  disabled={username.length < 3}
                  onClick={this.getPersonByUsername}
                >
                  <FontAwesomeIcon icon={faSearch} />
                  {' '}
                  Cargar
                </Button>
              </InputGroupAddon>
            </InputGroup>
          </FormGroup>
          {loading ? (
            <div className="text-center">
              <div className="sk-circle">
                <div className="sk-circle1 sk-child" />
                <div className="sk-circle2 sk-child" />
                <div className="sk-circle3 sk-child" />
                <div className="sk-circle4 sk-child" />
                <div className="sk-circle5 sk-child" />
                <div className="sk-circle6 sk-child" />
                <div className="sk-circle7 sk-child" />
                <div className="sk-circle8 sk-child" />
                <div className="sk-circle9 sk-child" />
                <div className="sk-circle10 sk-child" />
                <div className="sk-circle11 sk-child" />
                <div className="sk-circle12 sk-child" />
              </div>
              Cargando...
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    );
  }
}

SearchUserFromAD.propTypes = {
  loading: PropTypes.bool.isRequired,
  userExists: PropTypes.func.isRequired,
};

export default SearchUserFromAD;
