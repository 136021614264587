import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';

import BaseNavbar from 'components/BaseNavbar';
import EnvMessage from 'components/EnvMessage';
import LoggedInRoutes from 'routes/LoggedInRoutes';
import MissingTokenModal from 'components/UI/MissingTokenModal';
import { signout } from 'helpers/auth';
import 'styles/base.css';

const App = ({ userToken }) => {
  window.addEventListener('storage', (e) => {
    if (e.key === 'validToken') {
      signout();
    }
  });
  return (
    <React.Fragment>
      {process.env.REACT_APP_ENVIRONMENT && process.env.REACT_APP_ENVIRONMENT !== 'production' && (
        <EnvMessage />
      )}
      <BaseNavbar />
      <div className="container-fluid app-container">
        <Switch>
          <Route exact path="/login" render={() => <Redirect to="/" />} />
          {userToken && <LoggedInRoutes />}
        </Switch>
      </div>
      <MissingTokenModal />
    </React.Fragment>
  );
};

App.propTypes = {
  userToken: PropTypes.string,
};

App.defaultProps = {
  userToken: '',
};

const mapStateToProps = (state) => {
  return {
    userToken: state.login.userToken,
  };
};

export default withRouter(connect(mapStateToProps)(App));
