import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col } from 'reactstrap';
import _ from 'lodash';
import PageContainer from 'components/PageContainer';
import * as actions from 'redux-store/actions';
import { GET_USER_LIST, ACTIVATE_USER, USERS_GET_ALL_PROFILES } from 'redux-store/constants/action-types';
import { API, BASE_URL, SEARCH_DELAY_MS } from 'CONST';
import UserListTable from './UserListTable';
import isUserHasProfile from '../../helpers/profileValidator';
import { USER_WRITE } from '../../helpers/profilePermission';


const api = API.persons;

function mapDispatchToProps(dispatch) {
  return {
    getData: (users) => dispatch(actions.getData(users)),
    patchData: (user) => dispatch(actions.patchData(user)),
  };
}

class UserList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
    this.filtering = false;

    this.onClick = this.onClick.bind(this);
    this.onFilteredChange = this.onFilteredChange.bind(this);
    this.fetchStrategy = this.fetchStrategy.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.fetchPerfiles = this.fetchPerfiles.bind(this)
    this.fetchDataWithDebounce = _.debounce(this.fetchData, SEARCH_DELAY_MS);
  }

  componentDidMount() {
    this.fetchPerfiles();
  }

  onFilteredChange() {
    this.filtering = true;
  }

  onClick(e, instance) {
    const { patchData } = this.props;
    e.preventDefault();

    patchData({
      url: `${BASE_URL}${api}${instance.id}/`,
      config: { is_active: !instance.is_active },
      type: ACTIVATE_USER,
    });
  }

  fetchStrategy(tableState) {
    if (this.filtering) {
      return this.fetchDataWithDebounce(tableState);
    }
    return this.fetchData(tableState);
  }

  fetchData(state) {
    const { getData } = this.props;

    this.filtering = false;

    this.setState({
      loading: true,
    });
    let filters = `${BASE_URL}${api}?page=${state.page + 1}&`;
    if (state.filtered.length) {
      state.filtered.forEach((node) => {
        let validFilter = false;
        let filterValue = node.value;
        
        if (typeof node.value === 'number' && node.value > 0) {
          filterValue = node.value;
          validFilter = true;
        } else if (typeof node.value === 'string' && node.value.length > 2){
          filterValue = node.value;
          validFilter = true;
        }

        if (validFilter) {
          filters = `${filters}${node.id}=${filterValue}&`;
        }
      });
    }

    getData({
      url: filters,
      name: 'users',
      type: GET_USER_LIST,
    });
    this.setState({ loading: false });
  }

  fetchPerfiles() {
    const { getData } = this.props;
    this.setState({
      loading: true,
    });
    getData({
      url: `${BASE_URL}${API.perfiles}`,
      name: 'perfiles',
      type: USERS_GET_ALL_PROFILES,
    });

    this.setState({ loading: false });
  }

  render() {
    const { loading } = this.state;
    const { users } = this.props;
    return (
      <PageContainer breadcrumbs={[{ name: 'ORSAN' }, { name: 'LISTA DE USUARIOS' }]}>
        <Col md={{ offset: 10 }}>
          {isUserHasProfile(USER_WRITE) && (
            <Link
              style={{
                width: '100%',
                backgroundColor: '#F98724',
                border: '#F98724',
              }}
              className="btn btn-secondary"
              disabled={false}
              to="/users/create"
            >
              Crear Usuario desde AD
            </Link>
          )}
        </Col>
        <UserListTable
          count={users.listData.count}
          data={users.listData.data}
          loading={loading}
          pages={users.listData.pages}
          onFetchData={this.fetchStrategy}
          onFilteredChange={this.onFilteredChange}
          handleOnClick={this.onClick}
          profiles={users.profiles}
        />
      </PageContainer>
    );
  }
}

const mapStateToProps = (state) => ({ users: state.users });

const UserListView = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserList);

UserList.propTypes = {
  getData: PropTypes.func.isRequired,
  patchData: PropTypes.func.isRequired,
  users: PropTypes.shape({
    listData: PropTypes.shape({
      count: PropTypes.number,
      data: PropTypes.arrayOf(PropTypes.object),
      next: PropTypes.string,
      pages: PropTypes.number,
      previous: PropTypes.string,
    }),
  }).isRequired,
};

export default UserListView;
