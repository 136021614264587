import React from 'react';
import Select from 'react-select'
import PropTypes from 'prop-types';


const FilterSelect = ({ onFilterChange, filter, options, defaultOption, customOnChange }) => {

  if (defaultOption) {
    options.unshift(defaultOption);
  }
  return (
    <Select
      className="filter-select"
      onChange={value => {
        onFilterChange(value.value);
        if (customOnChange) {
          customOnChange(value.value);
        }
      }}
      value={
        filter ?
          options.find(elem => elem.value === filter.value) :
          options[0]}
      options={options}
      menuPosition="fixed"
      menuPortalTarget={document.body}
      styles={{ menuPortal: base => ({ ...base, zIndex: 99999 }) }}
    />
  )
};


FilterSelect.propTypes = {
  onFilterChange: PropTypes.func.isRequired,
  filter: PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.number
  }),
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    label: PropTypes.string,
  })).isRequired,
  defaultOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
  }),
  customOnChange: PropTypes.func,
};

FilterSelect.defaultProps = {
  filter: null,
  defaultOption: null,
  customOnChange: null
};

export default FilterSelect;
