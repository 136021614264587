import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  ModalBody,
  Button,
  Label,
  Input,
  FormGroup,
  Col,
} from 'reactstrap';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { post } from 'helpers/requests';
import { BASE_URL, API } from 'CONST';
import WarningModal from 'components/shared/WarningModal';
import SafeBtn from '../shared/SafeBtn';


const GroupCreateModal = ({
  isOpen,
  closeGroupModal,
  loadOptionsGroup
}) => {

  const [nombreGrupo, setNombreGrupo] = useState('');
  const [isOpenErrorModal, setIsOpenErrorModal] = useState(false);
  const [mensajeErrorModal, setMensajeErrorModal] = useState('Ha ocurrido un error al crear el grupo');
  const [loading, setLoading] = useState(false);

  const crearGrupo = () => {
    setLoading(true);
    const data = {
      nombre: nombreGrupo,
    };
    post(`${BASE_URL}${API.groups}`, data)
      .then(() => {
        loadOptionsGroup();
        closeGroupModal();
      })
      .catch((error) => {
        error.json().then((e) => setMensajeErrorModal(e.nombre[0]));
        setIsOpenErrorModal(true);
      })
      .finally(() => {
        setLoading(false);
        setNombreGrupo('');
      });
  }

  return (
    <>
      <Modal
        style={{maxWidth: '900px', width: '80%'}}
        isOpen={isOpen}
        type="modal-warning warning-container"
      >
        <ModalBody className="modal-body-grupo">
          <div className="form-box">
            <Col>
              <p className="form-title">Crear Grupo</p>
              <FormGroup className="row">
                <Label className="col-sm-3 col-form-label" for="creacion-grupo">
                  Nombre del grupo
                  <span className="required text-danger">*</span>
                </Label>
                <Col>
                  <Input
                    id="nombre-grupo"
                    type="text"
                    value={nombreGrupo || ""}
                    onChange={(event) => setNombreGrupo(event.target.value)}
                  />
                </Col>
              </FormGroup>
              <Col md={{ offset: 6 }}>
                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridGap: '10px'}}>
                  <Button
                    style={{ width: '100%' }}
                    onClick={() => {
                      setNombreGrupo('');
                      closeGroupModal();
                    }}
                  >
                    Cancelar
                  </Button>
                  <SafeBtn
                    className="form-button warning-accept"
                    onClick={crearGrupo}
                    style={{
                      width: '100%',
                      backgroundColor: '#F98724',
                      border: '#F98724',
                    }}
                    loading={loading}
                    disabled={!nombreGrupo}
                  >
                    <FontAwesomeIcon icon={faSave} className="mr-1" />
                    Crear grupo
                  </SafeBtn>
                </div>
              </Col>
            </Col>
          </div>
        </ModalBody>
      </Modal>
      <WarningModal
        isOpen={isOpenErrorModal}
        toggle={() => setIsOpenErrorModal(false)}
        handleOk={() => setIsOpenErrorModal(false)}
        handleClearError={() => {
          setIsOpenErrorModal(false);
          closeGroupModal();
        }}
        confirmMsg={mensajeErrorModal}
      />
    </>
  );
}

GroupCreateModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeGroupModal: PropTypes.func.isRequired,
  loadOptionsGroup: PropTypes.func.isRequired,
}

export default GroupCreateModal;