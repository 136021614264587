import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PageContainer from 'components/PageContainer';
import { getData, patchData, cleanUser } from 'redux-store/actions';
import {
  GET_USER_DETAIL,
  SAVE_USER,
  USERS_GET_ALL_PROFILES
} from 'redux-store/constants/action-types';
import { API, BASE_URL } from 'CONST';
import WarningAlert from 'components/UI/WarningAlert';
import CreateUserForm from './CreateUserForm';

const api = API.persons;

function mapDispatchToProps(dispatch) {
  return {
    getData: user => dispatch(getData(user)),
    cleanUser: user => dispatch(cleanUser(user)),
    patchData: data => dispatch(patchData(data)),
  };
}

class UserEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: null,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { getData: wrappedGetData, match } = this.props;
    const { id } = match.params;
    wrappedGetData({
      url: `${BASE_URL}${api}${id}/`,
      name: 'users',
      type: GET_USER_DETAIL,
    });
    wrappedGetData({
      url: `${BASE_URL}${API.perfiles}`,
      type: USERS_GET_ALL_PROFILES,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.users.userDetail) {
      this.setState({
        userData: nextProps.users.userDetail,
      });
    }
    if (nextProps.users.successUser) {
      const { cleanUser: resetStore, history } = this.props;
      resetStore();
      history.push('/users');
    }
  }

  handleSubmit(values) {
    const { patchData: saveUser, match } = this.props;
    const { id } = match.params;
    saveUser({
      url: `${BASE_URL}${api}${id}/`,
      type: SAVE_USER,
      config: values,
    });
  }

  render() {
    const { userData } = this.state;
    const { users } = this.props;
    return (
      <PageContainer
        breadcrumbs={[
          { name: 'ORSAN', url: '/users' },
          { name: 'Editar Usuario' },
        ]}
      >
        <CreateUserForm
          onSubmit={this.handleSubmit}
          userData={userData}
          allProfiles={users.profiles}
        />
        {users.saveErrorMessage ? (
          <WarningAlert text={users.saveErrorMessage} />
        ) : (
          ''
        )}
      </PageContainer>
    );
  }
}

const mapStateToProps = state => {
  return { users: state.users };
};

const UserEditView = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserEdit);

UserEdit.propTypes = {
  cleanUser: PropTypes.func.isRequired,
  getData: PropTypes.func.isRequired,
  history: PropTypes.shape({
    length: PropTypes.number,
  }).isRequired,
  match: PropTypes.shape({
    path: PropTypes.string
  }).isRequired,
  patchData: PropTypes.func.isRequired,
  users: PropTypes.shape({
    savedUser: PropTypes.shape({
      email: PropTypes.string,
      first_name: PropTypes.string,
      id: PropTypes.number,
      is_active: PropTypes.bool,
      job_title: PropTypes.string,
      last_name: PropTypes.string,
      organization_id: PropTypes.number,
      phone: PropTypes.string,
      rut: PropTypes.string,
      username: PropTypes.string,
    }),
    saveErrorMessage: PropTypes.string,
    successUser: PropTypes.bool,
    userDetail: PropTypes.shape({
      email: PropTypes.string,
      first_name: PropTypes.string,
      id: PropTypes.number,
      is_active: PropTypes.bool,
      job_title: PropTypes.string,
      last_name: PropTypes.string,
      organization_id: PropTypes.number,
      phone: PropTypes.string,
      rut: PropTypes.string,
      username: PropTypes.string,
    })
  }).isRequired,
};

export default UserEditView;
