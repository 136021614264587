import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';

import UserList from 'components/Users/UserList';
import UserDetail from 'components/Users/UserEdit';
import CreateUserFromAD from 'components/Users/CreateUserFromAD';
import ClientList from 'components/Clients/ClientList';
import ClientCreate from 'components/Clients/ClientCreate';
import ClientEdit from 'components/Clients/ClientEdit';
import ClientUsers from 'components/ClientUsers/ClientUsers';
import ClientUsersCreate from 'components/ClientUsers/ClientUsersCreate';
import ClientUserEdit from 'components/ClientUsers/ClientUserEdit';
import AppRedirect from '../components/shared/AppRedirect';
import isUserHasProfile from '../helpers/profileValidator';
import { USER_READ, CLIENT_READ, USER_WRITE, CLIENT_WRITE } from '../helpers/profilePermission';

const LoggedInRoutes = ({ user }) => {
  let UserListComponent;
  if (user.is_orsan && isUserHasProfile(USER_READ)) {
    UserListComponent = UserList;
  } else if (!user.is_orsan) {
    UserListComponent = ClientUsers;
  } else {
    UserListComponent = ClientList;
  }
  return !user.has_access ? (
    <AppRedirect />
  ) : (
    <Switch>
      <Route exact path="/" component={UserListComponent} />
      <Route exact path="/users" component={UserListComponent} />
      {isUserHasProfile(USER_READ) && <Route path="/users/edit/:id" component={UserDetail} />}
      {isUserHasProfile(USER_WRITE) && (
        <Route exact path="/users/create" component={CreateUserFromAD} />
      )}
      {isUserHasProfile(CLIENT_READ) && <Route exact path="/clients" component={ClientList} />}
      {isUserHasProfile(CLIENT_READ) && <Route path="/clients/edit/:id" component={ClientEdit} />}
      {isUserHasProfile(CLIENT_WRITE) && (
        <Route exact path="/clients/create" component={ClientCreate} />
      )}
      <Route exact path="/client-users" component={ClientUsers} />
      {user.is_orsan ? (
        isUserHasProfile(USER_WRITE) && (
          <Route path="/client-users/:id/create" component={ClientUsersCreate} />
        )
      ) : (
        <Route path="/client-users/:id/create" component={ClientUsersCreate} />
      )}
      {user.is_orsan ? (
        isUserHasProfile(USER_READ) && (
          <Route path="/client-users/edit/:id" component={ClientUserEdit} />
        )
      ) : (
        <Route path="/client-users/edit/:id" component={ClientUserEdit} />
      )}
    </Switch>
  );
};

LoggedInRoutes.propTypes = {
  user: PropTypes.shape({
    is_orsan: PropTypes.bool,
    has_access: PropTypes.bool,
  }).isRequired,
};

const mapStateToProps = (state) => {
  return {
    user: state.login.user,
    userToken: state.login.userToken,
  };
};

export default withRouter(connect(mapStateToProps)(LoggedInRoutes));
