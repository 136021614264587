
export const objectToOption = (object, labelField, index=null) => ({
  label: object[labelField],
  value: object,
  index,
});

export const objectToOptionForMultiSelect = (object, labelField) => ({
  label: object[labelField],
  value: object.id,
  object,
});

export const objectsToOptions = (objects, labelField, isMultiSelect = false) => {
  if (isMultiSelect) {
    return objects.map(obj => (objectToOptionForMultiSelect(obj, labelField)));
  }
  return objects.map((obj, index) => (objectToOption(obj, labelField, index)));
};
