import React from 'react';

export const headerOperation = (instance) => (
  <div className="text-center table-header">
    {instance}
  </div>
);

export const cellOperation = (instance, align = 'left', wrap = true) => (
  <div className={`text-${align} table-cell ${wrap ? 'wrap' : ''}`}>
    {(instance || instance === 0) ? instance : '-'}
  </div>
);

export const cellOperationManyDesc = (objs, wrap) => (
  <div className={`text-left table-cell ${wrap ? 'wrap' : ''}`}>
    {objs.map(obj => (
      <div key={obj.id}>
        {`- ${obj.name ? obj.name : obj.nombre}`}
      </div>
    ))}
  </div>
)
