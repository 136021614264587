import {
  CLEAN_CLIENT,
  ERROR_SAVE_CLIENT,
  GET_ACTIVIDADES,
  GET_CLIENT_DETAIL,
  GET_CLIENT_LIST,
  GET_CLIENT_ROLES,
  GET_CLIENT_LIST_SELECT,
  GET_COMUNAS,
  GET_ORGANIZATION_TYPE,
  GET_REGIONES,
  GET_GRUPOS,
  SAVE_CLIENT,
  GET_COMUNAS_ALIAS,
  VALIDATE_EXISTENT_RUT,
} from '../constants/action-types';

const initialState = {
  actividades: {
    data: [],
    pages: 0,
    count: undefined,
    previous: null,
    next: null,
  },
  clientList: [],
  clientDetail: undefined,
  comunas: [],
  comunasAlias: [],
  listData: {
    data: [],
    pages: 0,
    count: 0,
    previous: null,
    next: null,
  },
  organization_type: [],
  regiones: [],
  roles: [],
  savedClient: undefined,
  searchedClient: undefined,
  successClient: false,
  error: {},
  existentUser: null,
  grupos: [],
};

const executeGetClientSuccess = (state, action) => ({
  ...state,
  clientDetail: action.payload,
  saveErrorMessage: undefined,
});

const executeGetClientListSelectSuccess = (state, action) => {
  const clientList = action.payload.results.map(client => ({
    value: client.id,
    label:
      client.type_id === 1
        ? `${client.rut} - ${client.first_name} ${client.last_name}`
        : `${client.rut} - ${client.nombre_fantasia}`,
  }));
  return {
    ...state,
    clientList,
  };
};

const executeGetClientListSuccess = (state, action) => ({
  ...state,
  listData: {
    count: action.payload.count,
    data: action.payload.results,
    next: action.payload.next,
    pages: Math.ceil(action.payload.count / 20),
    previous: action.payload.previous,
  },
  saveErrorMessage: undefined,
});

const executeCleanClient = state => ({
  ...state,
  clientDetail: undefined,
  comunas: [],
  error: {},
  organization_type: [],
  regiones: [],
  roles: [],
  savedClient: undefined,
  saveErrorMessage: undefined,
  successClient: false,
});

const executeSaveClientSuccess = (state, action) => ({
  ...state,
  savedClient: action.payload.data,
  saveErrorMessage: undefined,
  successClient: true,
});

const executeGetOrganizationType = (state, action) => {
  const options = action.payload.map(node => ({
    label: node.name,
    value: node,
  }));
  return {
    ...state,
    organization_type: options,
  };
};

const executeGetComunas = (state, action) => {
  const options = action.payload.map(node => ({
    label: node.name,
    value: node,
  }));
  return {
    ...state,
    comunas: options,
  };
};

const getComunasAlias = (state, action) => ({
  ...state,
  comunasAlias: action.payload.map(node => ({
    label: node.name,
    value: node,
  })),
});

const executeGetRegiones = (state, action) => {
  const options = action.payload.map(node => ({
    label: node.name,
    value: node,
  }));
  return {
    ...state,
    regiones: options,
  };
};

const executeGetRoles = (state, action) => {
  const options = action.payload.map(node => ({
    label: node.name,
    value: node,
  }));
  return {
    ...state,
    roles: options,
  };
};

const executeGetActividades = (state, action) => {
  const data = action.payload.results.map(node => ({
    label: `${node.codigo} - ${node.nombre}`,
    value: node,
  }));
  return {
    ...state,
    actividades: {
      data,
      count: action.payload.count,
      next: action.payload.next,
      pages: Math.ceil(action.payload.count / 20),
      previous: action.payload.previous,
    },
  };
};

const executeGetGrupos = (state, action) => {
  const data = action.payload.results.map(node => ({
    label: node.nombre,
    value: node.id
  }));
  return {
    ...state,
    grupos: {
      data,
      count: action.payload.count,
      next: action.payload.next,
      pages: Math.ceil(action.payload.count / 20),
      previous: action.payload.previous,
    },
  };
};

const executeError = (state, action) => ({
  ...state,
  error: action.payload,
});

const validateExistentRut = (state, action) => ({
  ...state,
  existentUser: action.payload.results[0],
});

function clients(state = initialState, action) {
  switch (action.type) {
    case GET_CLIENT_DETAIL:
      return executeGetClientSuccess(state, action);
    case GET_CLIENT_LIST:
      return executeGetClientListSuccess(state, action);
    case GET_CLIENT_LIST_SELECT:
      return executeGetClientListSelectSuccess(state, action);
    case SAVE_CLIENT:
      return executeSaveClientSuccess(state, action);
    case CLEAN_CLIENT:
      return executeCleanClient(state, action);
    case GET_COMUNAS:
      return executeGetComunas(state, action);
    case GET_COMUNAS_ALIAS:
      return getComunasAlias(state, action);
    case GET_CLIENT_ROLES:
      return executeGetRoles(state, action);
    case GET_REGIONES:
      return executeGetRegiones(state, action);
    case GET_ACTIVIDADES:
      return executeGetActividades(state, action);
    case GET_GRUPOS:
      return executeGetGrupos(state, action);
    case GET_ORGANIZATION_TYPE:
      return executeGetOrganizationType(state, action);
    case ERROR_SAVE_CLIENT:
      return executeError(state, action);
    case VALIDATE_EXISTENT_RUT:
      return validateExistentRut(state, action);
    default:
      return state;
  }
}

export default clients;
