import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { ACCOUNTANT_URL, DOCS_URL, POLICY_URL, RISK_URL, WORKFLOW_URL, REASEGURO_URL, ANALYST_URL, ORSAN_LOGO_URL } from 'CONST';
import { signout } from 'helpers/auth';
import isUserHasProfile from '../helpers/profileValidator';
import { USER_READ, CLIENT_READ } from '../helpers/profilePermission';

class BaseNavbar extends Component {
  constructor(props) {
    super(props);

    this.toggleApps = this.toggleApps.bind(this);
    this.toggleOrsan = this.toggleOrsan.bind(this);
    this.toggleClients = this.toggleClients.bind(this);
    this.handleSignOut = this.handleSignOut.bind(this);
    this.state = {
      appsOpen: false,
      orsanOpen: false,
    };
  }

  handleSignOut = (event) => {
    event.preventDefault();
    signout();
  };

  toggleApps() {
    this.setState((state) => ({
      appsOpen: !state.appsOpen,
    }));
  }

  toggleOrsan() {
    this.setState((state) => ({
      orsanOpen: !state.orsanOpen,
    }));
  }

  toggleClients() {
    this.setState((state) => ({
      clientsOpen: !state.clientsOpen,
    }));
  }

  render() {
    const { user } = this.props;
    const { orsanOpen, clientsOpen, appsOpen } = this.state;
    return (
      user.username && (
        <nav className="navbar-content">
          <div className="nav-logo-container">
            <Link to="/users">
              <img src={ORSAN_LOGO_URL} className="nav-logo" alt="" />
            </Link>
          </div>
          <div style={{ display: 'grid', gridTemplateColumns: '1fr auto' }}>
            <ul className="nav-links-group">
              <Dropdown nav className="nav-link-element" isOpen={appsOpen} toggle={this.toggleApps}>
                <DropdownToggle nav caret className="navbar-header">
                  USUARIOS
                </DropdownToggle>
                <DropdownMenu>
                  {user.apps.includes('docs') && (
                    <DropdownItem>
                      <a className="nav-link navbar-header" href={DOCS_URL}>
                        DOCUMENTOS
                      </a>
                    </DropdownItem>
                  )}
                  {user.apps.includes('policies') && (
                    <DropdownItem>
                      <a className="nav-link navbar-header" href={POLICY_URL}>
                        PÓLIZA
                      </a>
                    </DropdownItem>
                  )}
                  {user.apps.includes('accountant') && (
                    <DropdownItem>
                      <a className="nav-link navbar-header" href={ACCOUNTANT_URL}>
                        RESERVAS
                      </a>
                    </DropdownItem>
                  )}
                  {user.apps.includes('reaseguro') && (
                    <DropdownItem>
                      <a className="nav-link navbar-header" href={REASEGURO_URL}>
                        REASEGURO
                      </a>
                    </DropdownItem>
                  )}
                  {user.apps.includes('risk') && (
                    <DropdownItem>
                      <a className="nav-link navbar-header" href={RISK_URL}>
                        RIESGO
                      </a>
                    </DropdownItem>
                  )}
                  {user.apps.includes('analista') && (
                    <DropdownItem>
                      <a className="nav-link navbar-header" href={ANALYST_URL}>
                        RIESGO DE CRÉDITO
                      </a>
                    </DropdownItem>
                  )}
                  {user.apps.includes('workflow') && (
                    <DropdownItem>
                      <a className="nav-link navbar-header" href={WORKFLOW_URL}>
                        WORKFLOW
                      </a>
                    </DropdownItem>
                  )}
                </DropdownMenu>
              </Dropdown>
              {user.is_orsan && (
                <Dropdown
                  nav
                  className="nav-link-element"
                  isOpen={orsanOpen}
                  toggle={this.toggleOrsan}
                >
                  <DropdownToggle nav caret>
                    Orsan
                  </DropdownToggle>
                  <DropdownMenu>
                    {isUserHasProfile(USER_READ) && (
                      <DropdownItem>
                        <Link className="nav-link" to="/users">
                          Lista de Usuarios
                        </Link>
                      </DropdownItem>
                    )}
                    {isUserHasProfile(CLIENT_READ) && (
                      <DropdownItem>
                        <Link className="nav-link" to="/clients">
                          Lista de Clientes
                        </Link>
                      </DropdownItem>
                    )}
                  </DropdownMenu>
                </Dropdown>
              )}

              {user.is_orsan ? (
                isUserHasProfile(USER_READ) && (
                  <Dropdown
                    nav
                    className="nav-link-element"
                    isOpen={clientsOpen}
                    toggle={this.toggleClients}
                  >
                    <DropdownToggle nav caret>
                      Clientes
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem>
                        <Link className="nav-link" to="/client-users">
                          Lista de usuarios
                        </Link>
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                )
              ) : (
                <Dropdown nav className="nav-link-element">
                  <Link className="nav-link" to="/client-users">
                    Usuarios
                  </Link>
                </Dropdown>
              )}
            </ul>
            <ul className="nav-links-group">
              <li className="nav-link-element nav-ite dropdown">
                <p className="nav-link">{user ? user.username : ''}</p>
              </li>
              <li className="nav-link-element nav-item dropdown">
                <Button className="nav-link" onClick={this.handleSignOut}>
                  Cerrar Sesión
                </Button>
              </li>
            </ul>
          </div>
        </nav>
      )
    );
  }
}

BaseNavbar.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string,
    full_name: PropTypes.string,
    has_access: PropTypes.bool,
    apps: PropTypes.arrayOf(PropTypes.string),
    organization: PropTypes.number,
    sub: PropTypes.string,
    is_orsan: PropTypes.bool,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  user: state.login.user,
});

export default withRouter(connect(mapStateToProps)(BaseNavbar));
