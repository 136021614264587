import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPowerOff } from '@fortawesome/free-solid-svg-icons';
import { cellOperation, cellOperationManyDesc, headerOperation } from 'helpers/tables';
import BaseTable from 'components/UI/BaseTable';
import FilterSelect from 'components/shared/FilterSelect';
import isUserHasProfile from 'helpers/profileValidator';
import { USER_WRITE } from 'helpers/profilePermission';

const NO_FILTERED_ID = -1;

const UserListTable = ({
  count,
  data,
  loading,
  onFetchData,
  pages,
  handleOnClick,
  onFilteredChange,
  profiles
}) => {
  const isDisabledActiveButton = !isUserHasProfile(USER_WRITE);
  const columns = [
    {
      Header: headerOperation('Username'),
      id: 'user__username__icontains',
      accessor: i =>
        cellOperation(
          <Link className="table-link" to={`/users/edit/${i.id}`}>
            {i.username}
          </Link>,
        ),
      minWidth: 100,
    },
    {
      Header: headerOperation('Nombre'),
      id: 'user__first_name__icontains',
      accessor: i => cellOperation(i.first_name),
      minWidth: 100,
    },
    {
      Header: headerOperation('Apellidos'),
      id: 'user__last_name__icontains',
      accessor: i => cellOperation(i.last_name),
      minWidth: 100,
    },
    {
      Header: headerOperation('Cargo'),
      id: 'job_title__icontains',
      accessor: i => cellOperation(i.job_title),
      minWidth: 100,
    },
    {
      Header: headerOperation('Perfil'),
      id: 'profiles__id',
      accessor: i => cellOperationManyDesc(i.profiles),
      minWidth: 100,
      Filter: (obj) => (
        <FilterSelect
          onFilterChange={obj.onChange}
          filter={obj.filter}
          options={
            profiles.map((elem) => (
              { label: elem.nombre, value: elem.id }
            ))
          }
          defaultOption={{ label: 'Todos', value: NO_FILTERED_ID }}
        />
      ),
    },
    {
      Header: headerOperation('RUT'),
      id: 'rut__icontains',
      accessor: i => cellOperation(i.rut),
      width: 110,
    },
    {
      Header: headerOperation('Teléfono'),
      id: 'phone',
      filterable: false,
      accessor: i => cellOperation(i.phone),
      width: 110,
    },
    {
      Header: headerOperation('Usuario'),
      id: 'userState',
      sortable: false,
      filterable: false,
      accessor: i => cellOperation(i.is_active ? 'Activo' : 'Inactivo'),
      width: 100,
    },
    {
      Header: headerOperation(''),
      id: 'actions',
      sortable: false,
      filterable: false,
      accessor: i => (
        <div
          className="table-cell"
          style={{
            paddingRight: '20px',
            textAlign: 'center',
          }}
        >
          <button
            onClick={e => handleOnClick(e, i)}
            className={`table-link ${i.is_active ? '' : 'disabled'}`}
            type="button"
            disabled={isDisabledActiveButton}
            style={{ cursor: isDisabledActiveButton && 'auto' }}
          >
            <FontAwesomeIcon icon={faPowerOff} />
          </button>
        </div>
      ),
      width: 60,
    },
  ];

  return (
    <div style={{ marginTop: '30px' }}>
      <BaseTable
        filterable
        resizable={false}
        loading={loading}
        data={data}
        columns={columns}
        showPagination
        resultCount={count}
        pages={pages}
        onFetchData={onFetchData}
        onFilteredChange={onFilteredChange}
      />
    </div>
  );
};

UserListTable.propTypes = {
  count: PropTypes.number,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleOnClick: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  pages: PropTypes.number.isRequired,
  onFetchData: PropTypes.func.isRequired,
  onFilteredChange: PropTypes.func.isRequired,
  profiles: PropTypes.arrayOf(PropTypes.object).isRequired
};

UserListTable.defaultProps = {
  count: 0,
};

export default UserListTable;
