const BASE_URL = process.env.REACT_APP_API;
const BASE_OAUTH = process.env.REACT_APP_OAUTH;
const CLIENT_ID = process.env.REACT_APP_CLIENT;
const LOCAL_URL = process.env.REACT_APP_LOCAL;
const DOCS_URL = process.env.REACT_APP_DOCS;
const POLICY_URL = process.env.REACT_APP_POLICY;
const ACCOUNTANT_URL = process.env.REACT_APP_ACCOUNTANT;
const REASEGURO_URL = process.env.REACT_APP_REASEGURO;
const RISK_URL = process.env.REACT_APP_RISK;
const WORKFLOW_URL = process.env.REACT_APP_WORKFLOW;
const ANALYST_URL = process.env.REACT_APP_ANALYST;
const SEARCH_DELAY_MS = process.env.REACT_APP_SEARCH_DELAY_MS;

const ORSAN_LOGO_URL = "https://orsan-static.s3.amazonaws.com/public/logotipo_orsan_seguros.png";

const API = {
  login: 'authorize',
  endSession: 'end-session',
  userinfo: 'userinfo',
  userCreate: 'v1/auth/ldap/user',
  persons: 'v1/auth/persons/',
  userAD: 'v1/auth/ldap/user',
  clients: 'v1/auth/organizations/',
  comunas: 'v1/geo/comunas/',
  regiones: 'v1/geo/regiones/',
  organization_type: 'v1/auth/organization_types/',
  actividad: 'v1/auth/actividad/',
  roles: 'v1/auth/roles/',
  perfiles: 'v1/auth/perfiles/',
  projects: 'v1/auth/proyectos_garantia/',
  groups: 'v1/auth/groups/',
};

const PROFILES = {
  ceo: 'ceo',
  consultaBasico: 'consulta-basico',
  comercialGarantia: 'comercial-garantia',
  suscripcionGarantia: 'suscripcion-garantia',
  gerenteRiesgoGarantia: 'gerente-riesgo-garantia',
  analistaRiesgoGarantia: 'analista-riesgo-garantia',
  comercialCredito: 'comercial-credito',
  riesgoCredito: 'riesgo-credito',
  fiscalia: 'fiscalia',
  actuariado: 'actuariado',
  gerenteOperaciones: 'gerente-operaciones',
  analistaOperaciones: 'analista-operaciones',
  riesgoOperacional: 'riesgo-operacional',
  siniestros: 'siniestros',
  recuperoSiniestros: 'recupero-siniestros',
  administrador: 'administrador',
  consultaEspecial: 'consulta-especial',
  gerenteGarantia: 'gerente-garantia',
  externoOrganizacion: 'externo-organizacion',
  externoProyecto: 'externo-proyecto',
};

const ROLES = {
  afianzadoGarantia: 'afianzadogarantia',
  afianzadoVentaVerde: 'afianzadoventaenverde',
  aseguradoCredito: 'aseguradocredito',
  aseguradoVentaVerde: 'aseguradoventaenverde',
  beneficiarioVentaVerde: 'beneficiarioventaenverde',
  filialCredito: 'filialcredito',
}

export {
  ACCOUNTANT_URL,
  API,
  BASE_URL,
  BASE_OAUTH,
  CLIENT_ID,
  LOCAL_URL,
  DOCS_URL,
  POLICY_URL,
  RISK_URL,
  WORKFLOW_URL,
  SEARCH_DELAY_MS,
  PROFILES,
  REASEGURO_URL,
  ANALYST_URL,
  ROLES,
  ORSAN_LOGO_URL
};

export const ROLES_ACTIVIDAD_REQUERIDA = [
  ROLES.afianzadoGarantia,
  ROLES.aseguradoCredito,
  ROLES.filialCredito,
];
