import React from 'react';
import { Alert } from 'reactstrap';
import PropTypes from 'prop-types';

const WarningAlert = props => {
  const { text } = props;
  return (
    <div>
      <Alert
        color="warning"
        style={{
          color: '#8a6d3b',
          background: '#fcf8e3',
          borderColor: '#faebcc',
          marginTop: '20px',
        }}
      >
        {text}
      </Alert>
    </div>
  );
};

WarningAlert.propTypes = {
  text: PropTypes.string.isRequired,
};

export default WarningAlert;
