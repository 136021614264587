import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';

import {
  Button,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import GroupCreateModal from './GroupCreateModal';
import validateRut from '../../helpers/rutFormat';
import { cleanClient, getData, postData } from '../../redux-store/actions';
import {
  GET_ACTIVIDADES,
  GET_CLIENT_ROLES,
  GET_COMUNAS,
  GET_COMUNAS_ALIAS,
  GET_ORGANIZATION_TYPE,
  GET_REGIONES,
  GET_GRUPOS,
  SAVE_CLIENT,
  VALIDATE_EXISTENT_RUT,
} from '../../redux-store/constants/action-types';
import {
  API,
  BASE_URL,
  SEARCH_DELAY_MS,
  ROLES_ACTIVIDAD_REQUERIDA,
  PROFILES,
} from '../../CONST';
import auth from '../../helpers/auth';
import PageContainer from '../PageContainer';
import AliasTable from '../UI/AliasTable';
import isUserHasProfile from '../../helpers/profileValidator';
import { handleRolesLogic } from '../../helpers/helpers';

class ClientCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rut: '',
      name: '',
      lastName: '',
      razonSocial: '',
      nombreFantasia: '',
      aliasAlias: '',
      aliasRegion: null,
      aliasComuna: null,
      aliasAddress: '',
      aliases: [],
      address: '',
      phone: '',
      comuna: undefined,
      region: undefined,
      roles: [],
      actividades: [],
      grupo: undefined,
      grupoModal: false,
      invalidRut: true,
      organizationType: { label: 'Persona Natural', value: { id: 1 } },
    };

    this.filterOption = this.filterOption.bind(this);
    this.filterOptionsWithDebounce = _.debounce(
      this.filterOption,
      SEARCH_DELAY_MS,
    );
    this.filterOptionGroup = this.filterOptionGroup.bind(this);
    this.filterOptionsGroupWithDebounce = _.debounce(
      this.filterOptionGroup,
      SEARCH_DELAY_MS,
    );
    this.handleChange = this.handleChange.bind(this);
    this.handleComunas = this.handleComunas.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handlePersonType = this.handlePersonType.bind(this);
    this.handleRegiones = this.handleRegiones.bind(this);
    this.handleRoles = this.handleRoles.bind(this);
    this.handleRut = this.handleRut.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.isDisabled = this.isDisabled.bind(this);
    this.submitAlias = this.submitAlias.bind(this);
  }

  componentDidMount() {
    const { cleanClient: resetStore, getData: getClientData } = this.props;
    resetStore();
    getClientData({
      url: `${BASE_URL}${API.regiones}?ordering=code`,
      type: GET_REGIONES,
    });
    getClientData({
      url: `${BASE_URL}${API.organization_type}`,
      type: GET_ORGANIZATION_TYPE,
    });
    getClientData({
      url: `${BASE_URL}${API.actividad}`,
      type: GET_ACTIVIDADES,
    });
    getClientData({
      url: `${BASE_URL}${API.roles}`,
      type: GET_CLIENT_ROLES,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.clients.successClient) {
      const { cleanClient: resetStore, history } = this.props;
      resetStore();
      history.push('/clients');
    }
  }

  loadOptions = inputValue =>
    new Promise(resolve => this.filterOptionsWithDebounce(inputValue, resolve));

  loadOptionsGroup = inputValue => {
    const {getData: getGrupos} = this.props;
    getGrupos({
      url: `${BASE_URL}${API.groups}`,
      type: GET_GRUPOS,
    });
    return new Promise(resolve => this.filterOptionsGroupWithDebounce(inputValue, resolve))
  }

  handleActividades = option => {
    this.setState({
      actividades: option || [],
    });
  };

  handleGrupo = option => {
    this.setState({
      grupo: option || undefined,
    });
  }

  filterOption = (inputValue, resolve) => {
    const url = `${BASE_URL}${API.actividad}?search=${inputValue}`;
    return auth.fetch(url).then(data => {
      const options = data.results.map(node => ({
        label: `${node.codigo} - ${node.nombre}`,
        value: node,
      }));
      resolve(options);
    });
  };

  filterOptionGroup = (inputValue, resolve) => {
    const url = `${BASE_URL}${API.groups}?search=${inputValue}`;
    return auth.fetch(url).then(data => {
      const options = data.results.map(node => ({
        label: node.nombre,
        value: node,
      }));
      resolve(options);
    })
  };

  handleAliasRegionChange = option => {
    const { getData: getComunas } = this.props;
    getComunas({
      url: `${BASE_URL}${API.comunas}?provincia__region__id=${option.value.id}`,
      type: GET_COMUNAS_ALIAS,
    });
    this.setState({
      aliasRegion: option,
    });
  };

  handleAliasComunaChange = option => {
    this.setState({
      aliasComuna: option,
    });
  };

  isDisabledAlias = () => {
    const { aliasAlias, aliasRegion, aliasComuna, aliasAddress } = this.state;
    const isValid = !!(
      aliasAlias &&
      ((aliasRegion && aliasComuna && aliasAddress) ||
        (!aliasRegion && !aliasComuna && !aliasAddress))
    );
    return !isValid;
  };

  submitAlias(e) {
    e.preventDefault();
    const { aliasAlias, aliasComuna, aliasAddress, aliases } = this.state;
    const list = [...aliases];
    const obj = {
      alias: aliasAlias,
      comunaId: null,
      comunaLabel: '',
      address: aliasAddress,
    };
    if (aliasComuna) {
      obj.comunaId = aliasComuna.value.id;
      obj.comunaLabel = aliasComuna.label;
    }
    list.push(obj);
    this.setState({
      aliasAlias: '',
      aliasRegion: null,
      aliasComuna: null,
      aliasAddress: '',
      aliases: list,
    });
  }

  handleDelete(e, i) {
    e.preventDefault();
    const { aliases } = this.state;
    const list = [...aliases];
    list.splice(list.indexOf(i), 1);
    this.setState({
      aliases: list,
    });
  }

  handleRut(e) {
    e.preventDefault();
    const { getData: getClientData } = this.props;
    const rut = e.target.value.replace(/[A-J]*[L-Z]*[a-j]*[l-z]*/g, '');
    const formatRut = validateRut(rut);

    if (formatRut.isValid) {
      getClientData({
        url: `${BASE_URL}${API.clients}?is_main=0&rut=${formatRut.formatRut}`,
        type: VALIDATE_EXISTENT_RUT,
      });
    }

    this.setState({
      rut: rut.length > 0 ? formatRut.formatRut : rut,
      invalidRut: !formatRut.isValid,
    });
  }

  handleChange(e) {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handlePersonType(option) {
    this.setState({
      organizationType: option,
    });
  }

  handleComunas(option) {
    this.setState({
      comuna: option,
    });
  }

  handleRegiones(option) {
    const { getData: getComunas } = this.props;
    getComunas({
      url: `${BASE_URL}${API.comunas}?provincia__region__id=${option.value.id}`,
      type: GET_COMUNAS,
    });
    this.setState({
      region: option,
    });
  }

  handleRoles(roles, e) {
    const { clients } = this.props
    const rolesTemp = handleRolesLogic(roles, e, clients.roles);

    this.setState({
      roles: rolesTemp || [],
    });
  }

  isDisabled() {
    const {
      comuna,
      invalidRut,
      lastName,
      name,
      nombreFantasia,
      organizationType,
      razonSocial,
      roles,
      actividades,
    } = this.state;
    const { clients } = this.props;

    const baseConditions =
      !invalidRut &&
      comuna !== undefined &&
      organizationType !== undefined &&
      roles.length > 0;

    const invalidActividades =
      roles.some(rol =>
        ROLES_ACTIVIDAD_REQUERIDA.includes(rol.value.natural_key),
      ) && actividades.length === 0;

    const personConditions =
      organizationType.value.id === 1
        ? name.length > 0 && lastName.length > 0
        : razonSocial.length > 0 && nombreFantasia.length > 0;

    const validRole = isUserHasProfile([
      PROFILES.ceo,
      PROFILES.consultaEspecial,
      PROFILES.comercialGarantia,
      PROFILES.comercialCredito,
      PROFILES.gerenteOperaciones,
    ]);

    return !(
      baseConditions &&
      personConditions &&
      !clients.existentUser &&
      !invalidActividades &&
      validRole
    );
  }

  handleSubmit() {
    const {
      actividades,
      address,
      aliases,
      comuna,
      lastName,
      name,
      nombreFantasia,
      organizationType,
      phone,
      razonSocial,
      roles,
      rut,
      grupo,
    } = this.state;
    const { postData: saveClient } = this.props;

    const config = {
      rut: rut.replace(/\./g, ''),
      comuna_id: comuna.value.id,
      address,
      type_id: organizationType.value.id,
      phone,
      roles_set: roles.map(rol => rol.value.id),
      actividades_set: actividades.map(node => node.value.id),
      grupo_id: grupo ? grupo.value.id : null,
    };

    if (organizationType.value.id === 1) {
      config.first_name = name;
      config.last_name = lastName;
    } else {
      config.nombre_fantasia = nombreFantasia;
      config.razon_social = razonSocial;
      config.aliases = aliases.map(alias => ({
        alias: alias.alias,
        comuna_id: alias.comunaId,
        address: alias.address,
      }));
    }
    saveClient({
      url: `${BASE_URL}${API.clients}`,
      type: SAVE_CLIENT,
      config,
    });
  }

  render() {
    const {
      aliasAlias,
      aliasRegion,
      aliasComuna,
      aliasAddress,
      aliases,
      address,
      comuna,
      invalidRut,
      lastName,
      name,
      nombreFantasia,
      organizationType,
      phone,
      razonSocial,
      region,
      roles,
      rut,
      grupoModal,
    } = this.state;
    const { clients } = this.props;

    const actividadesRequired = roles.some(rol =>
      ROLES_ACTIVIDAD_REQUERIDA.includes(rol.value.natural_key),
    );

    return (
      <PageContainer
        breadcrumbs={[
          { name: 'ORSAN', url: '/clients' },
          { name: 'CREAR CLIENTE' },
        ]}
      >
        <div className="form-box">
          <Col md="9">
            <p className="form-title">Información Cliente</p>
            <FormGroup className="row">
              <Label className="col-sm-2 col-form-label" for="rut">
                RUT
                <span className="required text-danger">*</span>
              </Label>
              <Col md={9}>
                <Input
                  name="rut"
                  id="rut"
                  value={rut}
                  onChange={this.handleRut}
                  placeholder="Ingrese RUT"
                  invalid={
                    rut.length > 10 && (invalidRut || clients.existentUser)
                  }
                />
                <FormFeedback style={{ fontSize: '14px', marginTop: '5px' }}>
                  {invalidRut && <div>El RUT ingresado es inválido</div>}
                  {!invalidRut && clients.existentUser && (
                    <div>
                      El RUT ingresado corresponde a un cliente existente.&nbsp;
                      <Link
                        to={`/clients/edit/${clients.existentUser.id}`}
                        style={{ color: '#315E90' }}
                      >
                        Ir a la edición.
                      </Link>
                    </div>
                  )}
                </FormFeedback>
              </Col>
            </FormGroup>
            <FormGroup className="row">
              <Label
                className="col-sm-2 col-form-label"
                for="organization_type"
              >
                Tipo de Persona
                <span className="required text-danger">*</span>
              </Label>
              <Col md={9}>
                <Select
                  id="organization_type"
                  className="Select"
                  name="organization_type"
                  clearable={false}
                  removeSelected={false}
                  options={clients.organization_type}
                  onChange={this.handlePersonType}
                  value={organizationType}
                />
              </Col>
            </FormGroup>

            <FormGroup
              className={`row ${
                organizationType.value.id === 2 ? 'd-none' : ''
              }`}
            >
              <Label className="col-sm-2 col-form-label" for="name">
                Nombre
                <span className="required text-danger">*</span>
              </Label>
              <Col md={9}>
                <Input
                  name="name"
                  id="name"
                  value={name}
                  onChange={this.handleChange}
                  placeholder="Ingrese nombre"
                />
              </Col>
            </FormGroup>
            <FormGroup
              className={`row ${
                organizationType.value.id === 2 ? 'd-none' : ''
              }`}
            >
              <Label className="col-sm-2 col-form-label" for="name">
                Apellidos
                <span className="required text-danger">*</span>
              </Label>
              <Col md={9}>
                <Input
                  name="lastName"
                  id="last_name"
                  value={lastName}
                  onChange={this.handleChange}
                  placeholder="Ingrese Apellido"
                />
              </Col>
            </FormGroup>
            <FormGroup
              className={`row ${
                organizationType.value.id === 1 ? 'd-none' : ''
              }`}
            >
              <Label className="col-sm-2 col-form-label" for="nombre_fantasia">
                Nombre de fantasía
                <span className="required text-danger">*</span>
              </Label>
              <Col md={9}>
                <Input
                  name="nombreFantasia"
                  id="nombre_fantasia"
                  value={nombreFantasia}
                  onChange={this.handleChange}
                  placeholder="Ingrese nombre de fantasía"
                />
              </Col>
            </FormGroup>
            <FormGroup
              className={`row ${
                organizationType.value.id === 1 ? 'd-none' : ''
              }`}
            >
              <Label className="col-sm-2 col-form-label" for="razon_social">
                Razón social
                <span className="required text-danger">*</span>
              </Label>
              <Col md={9}>
                <Input
                  name="razonSocial"
                  id="razon_social"
                  value={razonSocial}
                  onChange={this.handleChange}
                  placeholder="Ingrese razón social"
                />
              </Col>
            </FormGroup>
            <FormGroup className="row">
              <Label className="col-sm-2 col-form-label" for="region">
                Región
                <span className="required text-danger">*</span>
              </Label>
              <Col md={3}>
                <Select
                  id="region"
                  className="Select"
                  name="region"
                  clearable={false}
                  removeSelected={false}
                  placeholder="Seleccione una región"
                  options={clients.regiones}
                  onChange={this.handleRegiones}
                  value={region}
                />
              </Col>
              <Label className="col-sm-1 col-form-label" for="position">
                Comuna
                <span className="required text-danger">*</span>
              </Label>
              <Col md={5}>
                <Select
                  id="region"
                  className="Select"
                  name="region"
                  clearable={false}
                  removeSelected={false}
                  placeholder="Seleccione una comuna"
                  options={clients.comunas}
                  isDisabled={region === undefined}
                  onChange={this.handleComunas}
                  value={comuna}
                />
              </Col>
            </FormGroup>
            <FormGroup className="row">
              <Label className="col-sm-2 col-form-label" for="roles">
                Roles
                <span className="required text-danger">*</span>
              </Label>
              <Col md={9}>
                <Select
                  id="roles"
                  className="Select"
                  name="roles"
                  clearable={false}
                  removeSelected={false}
                  placeholder="Seleccione roles"
                  options={clients.roles}
                  onChange={this.handleRoles}
                  value={roles}
                  isMulti
                  getOptionValue={role => role.value.id}
                />
              </Col>
            </FormGroup>
            <FormGroup className="row">
              <Label className="col-sm-2 col-form-label" for="lastNames">
                Dirección
              </Label>
              <Col md={9}>
                <Input
                  name="address"
                  id="address"
                  value={address}
                  onChange={this.handleChange}
                  placeholder="Ingrese dirección"
                />
              </Col>
            </FormGroup>
            <FormGroup className="row">
              <Label className="col-sm-2 col-form-label" for="position">
                Actividades
                {actividadesRequired && (
                  <span className="required text-danger">*</span>
                )}
              </Label>
              <Col md={9}>
                <AsyncSelect
                  isMulti
                  id="actividad"
                  name="actividad"
                  cacheOptions
                  loadOptions={this.loadOptions}
                  defaultOptions
                  onChange={this.handleActividades}
                  placeholder="Seleccione una actividad"
                  styles={{ fontSize: '14px' }}
                  getOptionValue={actividad => actividad.value.id}
                />
              </Col>
            </FormGroup>
            <FormGroup className="row">
              <Label className="col-sm-2 col-form-label" for="group">
                Grupo
              </Label>
              <Col md={7}>
                <AsyncSelect
                  key={JSON.stringify(clients.grupos.data)}
                  id="grupo"
                  name="grupo"
                  cacheOptions
                  loadOptions={this.loadOptionsGroup}
                  defaultOptions
                  onChange={this.handleGrupo}
                  placeholder="Seleccione un grupo económico"
                  styles={{ fontSize: '14px' }}
                  getOptionValue={grupo => grupo.value.id}
                />
              </Col>
              <Col md={2}>
                <Button
                  style={{ width: '100%' }}
                  onClick={() => {this.setState({ grupoModal:true })}}
                >
                  Crear Grupo
                </Button>
              </Col>
            </FormGroup>
            <FormGroup className="row">
              <Label className="col-sm-2 col-form-label" for="phone">
                Teléfono
              </Label>
              <Col md={9}>
                <Input
                  name="phone"
                  id="phone"
                  value={phone}
                  onChange={this.handleChange}
                  placeholder="Ingrese teléfono"
                />
              </Col>
            </FormGroup>
            {organizationType.value.id !== 1 && (
              <FormGroup>
                <Label className="col-form-label" for="alias-form">
                  Alias
                </Label>
                <Row>
                  <Col md={11}>
                    <Form onSubmit={this.submitAlias}>
                      <div
                        className="alias-form"
                        style={{
                          display: 'grid',
                          gridTemplateColumns: '1fr 1fr 1fr 1fr',
                          gridGap: '10px',
                        }}
                      >
                        {aliases.length > 0 && (
                          <div style={{ gridColumn: '1 / 5' }}>
                            <AliasTable
                              data={aliases}
                              handleDelete={this.handleDelete}
                            />
                          </div>
                        )}
                        <Input
                          id="alias-alias"
                          name="aliasAlias"
                          value={aliasAlias}
                          onChange={this.handleChange}
                          placeholder="Ingrese un Alias"
                        />
                        <Select
                          id="alias-region"
                          name="aliasRegion"
                          className="Select"
                          clearable={false}
                          removeSelected={false}
                          placeholder="Seleccione una región"
                          options={clients.regiones}
                          onChange={this.handleAliasRegionChange}
                          value={aliasRegion}
                        />
                        <Select
                          id="alias-comuna"
                          name="aliasComuna"
                          className="Select"
                          clearable={false}
                          removeSelected={false}
                          placeholder="Seleccione una comuna"
                          options={clients.comunasAlias}
                          isDisabled={!aliasRegion}
                          onChange={this.handleAliasComunaChange}
                          value={aliasComuna}
                        />
                        <Input
                          name="aliasAddress"
                          id="aliasAddress"
                          value={aliasAddress}
                          onChange={this.handleChange}
                          placeholder="Ingrese una Dirección"
                          disabled={!aliasComuna}
                        />
                        <div style={{ gridColumn: 4 / 5 }}>
                          <Button
                            className="alias-button"
                            disabled={this.isDisabledAlias()}
                            type="submit"
                          >
                            Agregar Alias
                          </Button>
                        </div>
                      </div>
                    </Form>
                  </Col>
                </Row>
              </FormGroup>
            )}
            <Row>
              <div className="col-md-3">
                <Button
                  className="form-button"
                  style={{
                    margin: '20px 0 10px',
                    width: '100%',
                    backgroundColor: '#F98724',
                    border: '#F98724',
                  }}
                  disabled={this.isDisabled()}
                  onClick={e => this.handleSubmit(e, organizationType)}
                >
                  <FontAwesomeIcon icon={faSave} />
                  Guardar
                </Button>
              </div>
            </Row>
          </Col>
        </div>
        <GroupCreateModal
          isOpen={grupoModal}
          closeGroupModal={() => {this.setState({ grupoModal: false })}}
          loadOptionsGroup={() => this.loadOptionsGroup('')}
        />
      </PageContainer>
    );
  }
}

ClientCreate.propTypes = {
  cleanClient: PropTypes.func.isRequired,
  getData: PropTypes.func.isRequired,
  history: PropTypes.shape({
    length: PropTypes.number,
  }).isRequired,
  postData: PropTypes.func.isRequired,
  clients: PropTypes.shape({
    actividades: PropTypes.object,
    clientDetail: PropTypes.object,
    comunas: PropTypes.array,
    error: PropTypes.object,
    listData: PropTypes.object,
    organization_type: PropTypes.array,
    regiones: PropTypes.arrayOf(PropTypes.object),
    saveErrorMessage: PropTypes.string,
    savedClient: PropTypes.object,
    searchedClient: PropTypes.object,
    successClient: PropTypes.bool,
  }).isRequired,
};

const mapStateToProps = state => ({
  clients: state.clients,
});

const mapDispatchToProps = dispatch => ({
  getData: comunas => dispatch(getData(comunas)),
  postData: client => dispatch(postData(client)),
  cleanClient: client => dispatch(cleanClient(client)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ClientCreate);
