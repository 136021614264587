import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import WarningModal from 'components/shared/WarningModal';
import GroupCreateModal from './GroupCreateModal';
import { getData } from '../../redux-store/actions';
import { API, BASE_URL, ROLES_ACTIVIDAD_REQUERIDA } from '../../CONST';
import { GET_COMUNAS, GET_COMUNAS_ALIAS } from '../../redux-store/constants/action-types';
import { handleRolesLogic } from '../../helpers/helpers';
import validateRut from '../../helpers/rutFormat';
import AliasTable from '../UI/AliasTable';
import isUserHasProfile from '../../helpers/profileValidator';
import { CLIENT_WRITE } from '../../helpers/profilePermission';


class ClientEditForm extends Component {
  constructor(props) {
    super(props);
    const { clients } = this.props;

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.submitAlias = this.submitAlias.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleRegion = this.handleRegion.bind(this);
    this.handleRoles = this.handleRoles.bind(this);
    this.handleComuna = this.handleComuna.bind(this);
    this.handleRut = this.handleRut.bind(this);


    this.state = {
      address: clients.clientDetail.address,
      comuna: undefined,
      phone: clients.clientDetail.phone == null ? '' : clients.clientDetail.phone,
      region: undefined,
      roles: clients.clientDetail.roles,
      actividades: clients.clientDetail.actividades.map((node) => ({
        label: `${node.codigo}-${node.nombre}`,
        value: node,
      })),
      grupo: {
        label: clients.clientDetail.nombre_grupo,
        value: clients.clientDetail.grupo == null ? '' : clients.clientDetail.grupo.id
      },
      grupoModal: false,
      errorGrupo: false,
    };
    if (clients.clientDetail.type_id === 1) {
      this.state = {
        ...this.state,
        firstName: clients.clientDetail.first_name,
        lastName: clients.clientDetail.last_name,
      };
    } else {
      this.state = {
        ...this.state,
        aliasAlias: '',
        aliasRegion: null,
        aliasComuna: null,
        aliasAddress: '',
        aliases: clients.clientDetail.aliases.map((alias) => {
          const mappedAlias = {
            id: alias.id,
            alias: alias.alias,
            comunaId: null,
            comunaName: '',
            address: alias.address,
            can_delete: alias.can_delete,
          };
          if (alias.comuna) {
            mappedAlias.comunaId = alias.comuna.id;
            mappedAlias.comunaLabel = alias.comuna.name;
          }
          return mappedAlias;
        }),
        nombreFantasia: clients.clientDetail.nombre_fantasia,
        razonSocial: clients.clientDetail.razon_social,
      };
    }
  }

  componentDidMount() {
    const { clients, getData: getComunas } = this.props;
    getComunas({
      url: `${BASE_URL}${API.comunas}?provincia__region__id=${clients.clientDetail.region_id}`,
      type: GET_COMUNAS,
    });
  }

  componentWillReceiveProps(nextProps) {
    const { clients } = this.props;
    if (nextProps.clients.error.message && nextProps.clients.error.message !== clients.error.message){
      this.setState({ errorGrupo: true })
    }
  }

  handleActividades = (option) => {
    this.setState({
      actividades: option,
    });
  };

  handleGrupo = (option) => {
    this.setState({
      grupo: option || undefined,
    });
  };

  handleAliasRegionChange = (option) => {
    const { getData: getComunas } = this.props;
    getComunas({
      url: `${BASE_URL}${API.comunas}?provincia__region__id=${option.value.id}`,
      type: GET_COMUNAS_ALIAS,
    });
    this.setState({
      aliasRegion: option,
    });
  };

  handleAliasComunaChange = (option) => {
    this.setState({
      aliasComuna: option,
    });
  };

  isDisabledAlias = () => {
    const { aliasAlias, aliasRegion, aliasComuna, aliasAddress } = this.state;
    const isValid = !!(
      aliasAlias &&
      ((aliasRegion && aliasComuna && aliasAddress) ||
        (!aliasRegion && !aliasComuna && !aliasAddress))
    );
    return !isValid;
  };

  isDisabledByProfile = () => {
    const validRole = isUserHasProfile(CLIENT_WRITE);
    return !validRole;
  };

  handleRut(e) {
    e.preventDefault();
    const rut = e.target.value.replace(/[A-J]*[L-Z]*[a-j]*[l-z]*/g, '');
    this.setState({
      rut: rut.length > 0 ? validateRut(rut) : rut,
    });
  }

  handleChange(e) {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleComuna(option) {
    this.setState({
      comuna: option,
    });
  }

  handleRegion(option) {
    const { getData: getComunas } = this.props;
    getComunas({
      url: `${BASE_URL}${API.comunas}?provincia__region__id=${option.value.id}`,
      type: GET_COMUNAS,
    });
    this.setState({
      region: option,
    });
  }

  submitAlias(e) {
    e.preventDefault();
    const { aliasAlias, aliasComuna, aliasAddress, aliases } = this.state;
    const list = [...aliases];
    const obj = {
      alias: aliasAlias,
      comunaId: null,
      comunaLabel: '',
      address: aliasAddress,
      can_delete: true,
    };
    if (aliasComuna) {
      obj.comunaId = aliasComuna.value.id;
      obj.comunaLabel = aliasComuna.label;
    }
    list.push(obj);
    this.setState({
      aliasAlias: '',
      aliasRegion: null,
      aliasComuna: null,
      aliasAddress: '',
      aliases: list,
    });
  }

  handleDelete(e, i) {
    e.preventDefault();
    const { aliases } = this.state;
    const list = [...aliases];
    list.splice(list.indexOf(i), 1);
    this.setState({
      aliases: list,
    });
  }

  handleRoles(roles, e) {
    const { clients } = this.props
    const rolesTemp = handleRolesLogic(roles, e, clients.roles);

    this.setState({
      roles: (rolesTemp && rolesTemp.map((rol) => rol.value)) || [],
    });
  }

  isDisabled() {
    const { firstName, lastName, razonSocial, nombreFantasia, roles, actividades } = this.state;
    const { clients } = this.props;

    let baseConditions;
    if (clients.clientDetail.type_id === 1) {
      baseConditions = [firstName, lastName, roles].some((value) => value.length === 0);
    } else {
      baseConditions = [razonSocial, nombreFantasia, roles].some((value) => value.length === 0);
    }

    const invalidActividades =
      roles.some((rol) => ROLES_ACTIVIDAD_REQUERIDA.includes(rol.natural_key)) &&
      (!actividades || actividades.length === 0);

    return baseConditions || invalidActividades;
  }

  handleSubmit() {
    const {
      actividades,
      aliases,
      address,
      comuna,
      firstName,
      lastName,
      nombreFantasia,
      phone,
      razonSocial,
      roles,
      grupo
    } = this.state;
    const { clients, onSubmit } = this.props;
    const actividadesSet = (actividades || []).map((node) => node.value.id);
    const config = {
      address,
      comuna_id: comuna ? comuna.value.id : clients.clientDetail.comuna_id,
      phone,
      roles_set: roles.map((rol) => rol.id),
      actividades_set: actividadesSet,
      grupo_id: grupo ? grupo.value.id : null,
    };
    if (clients.clientDetail.type_id === 1) {
      config.first_name = firstName;
      config.last_name = lastName;
      onSubmit(config);
    } else {
      config.nombre_fantasia = nombreFantasia;
      config.razon_social = razonSocial;
      config.aliases = aliases.map((alias) => ({
        id: alias.id,
        alias: alias.alias,
        comuna_id: alias.comunaId,
        address: alias.address,
      }));
      onSubmit(config);
    }
  }

  render() {
    const { loadOptions, loadOptionsGroup, clients } = this.props;
    const {
      actividades,
      aliasAlias,
      aliasRegion,
      aliasComuna,
      aliasAddress,
      aliases,
      address,
      comuna,
      firstName,
      lastName,
      nombreFantasia,
      phone,
      razonSocial,
      region,
      roles,
      grupo,
      grupoModal,
      errorGrupo,
    } = this.state;


    const roleValues = roles.map((rol) => ({
      label: rol.name,
      value: rol,
    }));
    const initialRegion = clients.regiones.find(
      (node) => node.value.id === clients.clientDetail.region_id,
    );
    const initialComuna = clients.comunas.find(
      (node) => node.value.id === clients.clientDetail.comuna_id,
    );

    const actividadesRequired = roles.some((rol) =>
      ROLES_ACTIVIDAD_REQUERIDA.includes(rol.natural_key),
    );

    return (
      <div>
        <div className="form-box">
          <Col md="9">
            <p className="form-title">Información Cliente</p>
            <FormGroup className="row">
              <Label className="col-sm-2 col-form-label" for="rut">
                RUT
                <span className="required text-danger">*</span>
              </Label>
              <Col md={9}>
                <Input
                  readOnly
                  name="rut"
                  id="rut"
                  value={
                    clients.clientDetail ? validateRut(clients.clientDetail.rut).formatRut : ''
                  }
                  placeholder="Ingrese RUT"
                />
              </Col>
            </FormGroup>
            {clients.clientDetail.type_id === 1 ? (
              <div>
                <FormGroup className="row">
                  <Label className="col-sm-2 col-form-label" for="name">
                    Nombre
                    <span className="required text-danger">*</span>
                  </Label>
                  <Col md={9}>
                    <Input
                      name="firstName"
                      id="first_name"
                      value={firstName}
                      onChange={this.handleChange}
                      disabled={this.isDisabledByProfile()}
                      placeholder="Ingrese nombre"
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label className="col-sm-2 col-form-label" for="name">
                    Apellidos
                    <span className="required text-danger">*</span>
                  </Label>
                  <Col md={9}>
                    <Input
                      name="lastName"
                      id="last_name"
                      value={lastName}
                      onChange={this.handleChange}
                      disabled={this.isDisabledByProfile()}
                      placeholder="Ingrese apellido"
                    />
                  </Col>
                </FormGroup>
              </div>
            ) : (
              <div>
                <FormGroup className="row">
                  <Label className="col-sm-2 col-form-label" for="nombre_fantasia">
                    Nombre de fantasía
                    <span className="required text-danger">*</span>
                  </Label>
                  <Col md={9}>
                    <Input
                      name="nombreFantasia"
                      id="nombre_fantasia"
                      value={nombreFantasia}
                      onChange={this.handleChange}
                      disabled={this.isDisabledByProfile()}
                      placeholder="Ingrese razón social"
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label className="col-sm-2 col-form-label" for="razon_social">
                    Razón social
                    <span className="required text-danger">*</span>
                  </Label>
                  <Col md={9}>
                    <Input
                      name="razonSocial"
                      id="razon_social"
                      value={razonSocial}
                      onChange={this.handleChange}
                      disabled={this.isDisabledByProfile()}
                      placeholder="Ingrese razón social"
                    />
                  </Col>
                </FormGroup>
              </div>
            )}
            <FormGroup className="row">
              <Label className="col-sm-2 col-form-label" for="region">
                Región
                <span className="required text-danger">*</span>
              </Label>
              <Col md={3}>
                <Select
                  id="region"
                  className="Select"
                  name="region"
                  clearable={false}
                  removeSelected={false}
                  placeholder="Seleccione una región"
                  options={clients.regiones}
                  onChange={this.handleRegion}
                  isDisabled={this.isDisabledByProfile()}
                  value={region || initialRegion}
                />
              </Col>
              <Label className="col-sm-1 col-form-label" for="position">
                Comuna
                <span className="required text-danger">*</span>
              </Label>
              <Col md={5}>
                <Select
                  id="region"
                  className="Select"
                  name="region"
                  clearable={false}
                  removeSelected={false}
                  placeholder="Seleccione una comuna"
                  options={clients.comunas}
                  isDisabled={
                    (region === undefined && initialRegion === undefined) ||
                    this.isDisabledByProfile()
                  }
                  onChange={this.handleComuna}
                  value={comuna || initialComuna}
                />
              </Col>
            </FormGroup>
            <FormGroup className="row">
              <Label className="col-sm-2 col-form-label" for="roles">
                Roles
                <span className="required text-danger">*</span>
              </Label>
              <Col md={9}>
                <Select
                  id="roles"
                  className="Select"
                  name="roles"
                  clearable={false}
                  removeSelected={false}
                  placeholder="Seleccione roles"
                  options={clients.roles}
                  onChange={this.handleRoles}
                  isDisabled={this.isDisabledByProfile()}
                  value={roleValues}
                  isMulti
                  getOptionValue={role => role.value.id}
                />
              </Col>
            </FormGroup>
            <FormGroup className="row">
              <Label className="col-sm-2 col-form-label" for="lastNames">
                Dirección
              </Label>
              <Col md={9}>
                <Input
                  name="address"
                  id="address"
                  value={address}
                  onChange={this.handleChange}
                  disabled={this.isDisabledByProfile()}
                  placeholder="Ingrese dirección"
                />
              </Col>
            </FormGroup>
            <FormGroup className="row">
              <Label className="col-sm-2 col-form-label" for="position">
                Actividades
                {actividadesRequired && <span className="required text-danger">*</span>}
              </Label>
              <Col md={9}>
                <AsyncSelect
                  isMulti
                  id="actividad"
                  name="actividad"
                  cacheOptions
                  loadOptions={loadOptions}
                  defaultOptions
                  defaultValue={actividades}
                  onChange={this.handleActividades}
                  isDisabled={this.isDisabledByProfile()}
                  placeholder="Seleccione una actividad"
                  styles={{ fontSize: '14px' }}
                  getOptionValue={actividad => actividad.value.id}
                />
              </Col>
            </FormGroup>
            <FormGroup className="row">
              <Label className="col-sm-2 col-form-label" for="group">
                Grupo
              </Label>
              <Col md={7}>
                <AsyncSelect
                  key={JSON.stringify(clients.grupos.data)}
                  id="grupo"
                  name="grupo"
                  loadOptions={loadOptionsGroup}
                  defaultOptions
                  defaultValue={grupo}
                  onChange={this.handleGrupo}
                  placeholder="Seleccione un grupo económico"
                  styles={{ fontSize: '14px' }}
                  getOptionValue={group => group.value}
                  isClearable
                />
              </Col>
              <Col md={2}>
                <Button
                  style={{ width: '100%' }}
                  onClick={() => {this.setState({ grupoModal:true })}}
                >
                  Crear Grupo
                </Button>
              </Col>
            </FormGroup>
            <FormGroup className="row">
              <Label className="col-sm-2 col-form-label" for="phone">
                Teléfono
              </Label>
              <Col md={9}>
                <Input
                  name="phone"
                  id="phone"
                  value={phone}
                  onChange={this.handleChange}
                  disabled={this.isDisabledByProfile()}
                  placeholder="Ingrese teléfono"
                />
              </Col>
            </FormGroup>
            {clients.clientDetail.type_id !== 1 && (
              <FormGroup>
                <Label className="col-form-label" for="alias-form">
                  Alias
                </Label>
                <Row>
                  <Col md={11}>
                    <Form onSubmit={this.submitAlias}>
                      <div
                        className="alias-form"
                        style={{
                          display: 'grid',
                          gridTemplateColumns: '1fr 1fr 1fr 1fr',
                          gridGap: '10px',
                        }}
                      >
                        {aliases.length > 0 && (
                          <div style={{ gridColumn: '1 / 5' }}>
                            <AliasTable data={aliases} handleDelete={this.handleDelete} />
                          </div>
                        )}
                        <Input
                          id="alias-alias"
                          name="aliasAlias"
                          value={aliasAlias}
                          onChange={this.handleChange}
                          disabled={this.isDisabledByProfile()}
                          placeholder="Ingrese un Alias"
                        />
                        <Select
                          id="alias-region"
                          name="aliasRegion"
                          className="Select"
                          clearable={false}
                          removeSelected={false}
                          placeholder="Seleccione una región"
                          options={clients.regiones}
                          onChange={this.handleAliasRegionChange}
                          isDisabled={this.isDisabledByProfile()}
                          value={aliasRegion}
                        />
                        <Select
                          id="alias-comuna"
                          name="aliasComuna"
                          className="Select"
                          clearable={false}
                          removeSelected={false}
                          placeholder="Seleccione una comuna"
                          options={clients.comunasAlias}
                          isDisabled={!aliasRegion || this.isDisabledByProfile()}
                          onChange={this.handleAliasComunaChange}
                          value={aliasComuna}
                        />
                        <Input
                          name="aliasAddress"
                          id="aliasAddress"
                          value={aliasAddress}
                          onChange={this.handleChange}
                          placeholder="Ingrese una Dirección"
                          disabled={!aliasComuna || this.isDisabledByProfile()}
                        />
                        <div style={{ gridColumn: 4 / 5 }}>
                          <Button
                            className="alias-button"
                            disabled={this.isDisabledAlias() || this.isDisabledByProfile()}
                            type="submit"
                          >
                            Agregar Alias
                          </Button>
                        </div>
                      </div>
                    </Form>
                  </Col>
                </Row>
              </FormGroup>
            )}
            {!this.isDisabledByProfile() && (
              <Row>
                <div className="col-md-3">
                  <Button
                    style={{
                      margin: '20px 0 10px',
                      width: '100%',
                      backgroundColor: '#F98724',
                      border: '#F98724',
                    }}
                    disabled={this.isDisabled()}
                    onClick={this.handleSubmit}
                  >
                    <FontAwesomeIcon icon={faSave} />
                    Guardar
                  </Button>
                </div>
              </Row>
            )}
          </Col>
        </div>
        <GroupCreateModal
          isOpen={grupoModal}
          closeGroupModal={() => {this.setState({ grupoModal: false })}}
          loadOptionsGroup={() => loadOptionsGroup('')}
        />
        <WarningModal
          confirmMsg=''
          handleOk={() => this.setState({errorGrupo: false})}
          toggle={() => this.setState({errorGrupo: false})}
          handleClearError={() => this.setState({errorGrupo: false})}
          errorMsg={clients.error.message}
          isOpen={errorGrupo}
        />
      </div>
    );
  }
}

ClientEditForm.propTypes = {
  clients: PropTypes.shape({
    actividades: PropTypes.object,
    clientDetail: PropTypes.object,
    comunas: PropTypes.array,
    roles: PropTypes.array,
    error: PropTypes.object,
    listData: PropTypes.object,
    organization_type: PropTypes.array,
    regiones: PropTypes.arrayOf(PropTypes.object),
    saveErrorMessage: PropTypes.string,
    savedClient: PropTypes.object,
    searchedClient: PropTypes.object,
    successClient: PropTypes.bool,
  }).isRequired,
  getData: PropTypes.func.isRequired,
  loadOptions: PropTypes.func.isRequired,
  loadOptionsGroup: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  clients: state.clients,
});

const mapDispatchToProps = (dispatch) => ({
  getData: (client) => dispatch(getData(client)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ClientEditForm);
