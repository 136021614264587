import React from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';

const shouldShowPagination = (showPagination, resultCount, pageSize) => {
  if (showPagination !== undefined ? showPagination : true) {
    return resultCount > pageSize;
  }
  return false;
};

const BaseTable = ({
  tableRef,
  data,
  className,
  loading,
  pages,
  resultCount,
  filtered,
  filterable,
  filteredArgs,
  columns,
  defaultFilterMethod,
  defaultSorted,
  defaultSortDesc,
  onFetchData,
  onFilteredChange,
  onPageChange,
  showPagination,
  resizable,
}) => {
  const pageSize = 20;

  return (
    <ReactTable
      manual
      className={`-highlight ${ className || ''}`}
      loadingText="Cargando..."
      pageSize={pageSize}
      defaultPageSize={10}
      minRows={1}
      pageText="Página"
      previousText="Anterior"
      nextText="Siguiente"
      noDataText="Sin resultados"
      rowsText="Filas"
      multiSort={false}
      showPageSizeOptions={false}
      showPagination={shouldShowPagination(
        showPagination,
        resultCount,
        pageSize,
      )}
      ref={tableRef}
      resizable={resizable}
      data={data}
      loading={loading}
      pages={pages}
      filterable={filterable}
      filtered={filtered}
      filteredArgs={filteredArgs}
      columns={columns}
      defaultFilterMethod={defaultFilterMethod}
      defaultSorted={defaultSorted}
      defaultSortDesc={defaultSortDesc || false}
      onPageChange={onPageChange}
      onFetchData={onFetchData}
      onFilteredChange={onFilteredChange}
      // getTrProps={(state, rowInfo, column, instance) => {
      //   const style = {
      //     style: {
      //       background: 'red',
      //     },
      //   };
      //   return style;
      // }}
      getTheadProps={() => {
        const style = {
          style: {
            background: '#EFEFEF',
            padding: '10px 0 0 10px',
          },
        };
        return style;
      }}
      getTheadThProps={() => {
        const style = {
          style: {
            border: 'none',
          },
        };
        return style;
      }}
      getTrGroupProps={(state, rowInfo) => {
        const isAlias = rowInfo ? 'alias' in rowInfo.original : false;
        const style = !isAlias ? {
          style: {
            border: 'none',
          },
        } : {
          style: {
            borderBottom: '1px solid #CCCCCC',
          }
        };
        return style;
      }}
      getTdProps={() => {
        const style = {
          style: {
            border: 'none',
          },
        };
        return style;
      }}
      getTableProps={() => ({
        style: { overflowX: 'scroll' },
      })}
    />
  );
};

BaseTable.propTypes = [];

export default BaseTable;
