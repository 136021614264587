import React, { Component } from 'react';
import PropTypes from 'prop-types';
import connect from 'react-redux/es/connect/connect';
import { getData, postData, cleanUser, cleanClient } from 'redux-store/actions';
import {
  SAVE_USER,
  USERS_GET_ALL_PROFILES,
  GET_CLIENT_LIST_SELECT,
  GET_CLIENT_DETAIL,
} from 'redux-store/constants/action-types';
import CreateUserForm from 'components/CreateUserForm';
import { emailValidate } from 'helpers/helpers';
import PageContainer from 'components/PageContainer';
import WarningAlert from 'components/UI/WarningAlert';
import { API, BASE_URL, PROFILES, ROLES } from 'CONST';
import store from 'redux-store/store'
import isUserHasProfile from '../../helpers/profileValidator';
import { USER_WRITE } from '../../helpers/profilePermission';

function mapDispatchToProps(dispatch) {
  return {
    getData: user => dispatch(getData(user)),
    cleanUser: user => dispatch(cleanUser(user)),
    postData: user => dispatch(postData(user)),
    cleanClient: client => dispatch(cleanClient(client)),
  };
}

function getForeignOptions(values) {
  return values.filter(value => [PROFILES.externoProyecto, PROFILES.externoOrganizacion].includes(value.slug))
}

class ClientUsersCreate extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      invalidEmail: true,
      invalidRut: false,
    };
  }

  componentDidMount() {
    const { getData: getUserDetail, match } = this.props;
    getUserDetail({
      url: `${BASE_URL}${API.perfiles}`,
      type: USERS_GET_ALL_PROFILES,
    });
    getUserDetail({
      url: `${BASE_URL}${API.clients}${match.params.id}/`,
      name: 'clients',
      type: GET_CLIENT_DETAIL,
    });
    getUserDetail({
      url: `${BASE_URL}${API.clients}?roles__natural_key=${ROLES.afianzadoVentaVerde}`,
      name: 'clients',
      type: GET_CLIENT_LIST_SELECT,
    });
  }

  componentWillReceiveProps(nextProps) {
    const { cleanUser: resetStore, history } = this.props;
    if (nextProps.users.saveErrorMessage) {
      this.setState(() => ({
        loading: false,
      }));
    }
    if (nextProps.users.successUser) {
      resetStore();
      history.push('/client-users');
    }
  }

  componentWillUnmount() {
    const { cleanClient: resetStoreClient } = this.props;
    resetStoreClient();
  }

  handleSubmit(values) {
    const { match, postData: saveUser } = this.props;
    const { id } = match.params;
    const config = {
      ...values,
      organization_id: id,
    }
    saveUser({
      url: `${BASE_URL}${API.persons}`,
      type: SAVE_USER,
      config,
    });
    this.setState({
      loading: true,
    });
  }

  isDisabled() {
    const { user } = store.getState().login;
    const { email, firstName, invalidEmail, invalidRut, jobTitle, lastName } = this.state;
    if (
      !invalidRut &&
      firstName &&
      lastName &&
      jobTitle &&
      !invalidEmail &&
      (isUserHasProfile(USER_WRITE) || user.is_coordinator)
    ) {
      return !emailValidate(email);
    }
    return true;
  }

  render() {
    const { loading } = this.state;
    const { users, clients } = this.props;
    return (
      <PageContainer
        breadcrumbs={[{ name: 'CLIENTES', url: '/client-users' }, { name: 'CREAR USUARIO' }]}
      >
        {users.saveErrorMessage && !loading && <WarningAlert text={users.saveErrorMessage} />}
        <CreateUserForm
          onSubmit={this.handleSubmit}
          allProfiles={getForeignOptions(users.profiles)}
          organizations={clients.clientList}
          isOrsan={clients.clientDetail && clients.clientDetail.is_main}
        />
      </PageContainer>
    );
  }
}

ClientUsersCreate.propTypes = {
  cleanUser: PropTypes.func.isRequired,
  cleanClient: PropTypes.func.isRequired,
  getData: PropTypes.func.isRequired,
  postData: PropTypes.func.isRequired,
  history: PropTypes.shape({
    length: PropTypes.number,
  }).isRequired,
  match: PropTypes.shape({
    path: PropTypes.string,
  }).isRequired,
  users: PropTypes.shape({
    savedUser: PropTypes.object,
    saveErrorMessage: PropTypes.string,
    searchedUser: PropTypes.shape({
      email: PropTypes.string,
      first_name: PropTypes.string,
      job_title: PropTypes.string,
      last_name: PropTypes.string,
      organization_id: PropTypes.number,
      username: PropTypes.string,
    }),
    successUser: PropTypes.bool.isRequired,
  }).isRequired,
  clients: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => ({
  users: state.users,
  clients: state.clients,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ClientUsersCreate);
