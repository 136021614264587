import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { getData, patchData, cleanClient } from '../../redux-store/actions';
import {
  GET_ACTIVIDADES,
  GET_CLIENT_DETAIL, GET_CLIENT_ROLES,
  GET_COMUNAS,
  GET_GRUPOS,
  GET_REGIONES,
  SAVE_CLIENT,
} from '../../redux-store/constants/action-types';
import { API, BASE_URL, SEARCH_DELAY_MS } from '../../CONST';
import sso from '../../helpers/auth';

import PageContainer from '../PageContainer';
import ClientEditForm from './ClientEditForm';

function mapDispatchToProps(dispatch) {
  return {
    getData: client => dispatch(getData(client)),
    cleanClient: client => dispatch(cleanClient(client)),
    patchData: data => dispatch(patchData(data)),
  };
}

class ClientEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialActividades: undefined,
    };
    this.handleComunas = this.handleComunas.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.filterOption = this.filterOption.bind(this);
    this.filterOptionsWithDebounce = _.debounce(
      this.filterOption,
      SEARCH_DELAY_MS
    );
    this.filterOptionGroup = this.filterOptionGroup.bind(this);
    this.filterOptionsGroupWithDebounce = _.debounce(
      this.filterOptionGroup,
      SEARCH_DELAY_MS
    );
  }

  componentDidMount() {
    const {
      cleanClient: resetStore,
      getData: getClientData,
      match,
    } = this.props;
    const { id } = match.params;
    resetStore();
    getClientData({
      url: `${BASE_URL}${API.clients}${id}/`,
      name: 'clients',
      type: GET_CLIENT_DETAIL,
    });
    getClientData({
      url: `${BASE_URL}${API.regiones}?ordering=code`,
      type: GET_REGIONES,
    });
    getClientData({
      url: `${BASE_URL}${API.actividad}`,
      type: GET_ACTIVIDADES,
    });
    getClientData({
      url: `${BASE_URL}${API.roles}`,
      type: GET_CLIENT_ROLES,
    });
    getClientData({
      url: `${BASE_URL}${API.groups}`,
      type: GET_GRUPOS,
    })
  }

  componentWillReceiveProps(nextProps) {
    const { cleanClient: resetStore, history } = this.props;
    if (nextProps.clients.successClient) {
      resetStore();
      history.push('/clients');
    }
  }

  filterOption = (inputValue, resolve) => {
    const url = `${BASE_URL}${API.actividad}?search=${inputValue}`;
    return sso.fetch(url, { method: 'get' })
      .then(data => {
        const options = data.results.map(node => ({ label: `${node.codigo} - ${node.nombre}`, value: node }));
        resolve(options);
      })
  };

  filterOptionGroup = (inputValue, resolve) => {
    const url = `${BASE_URL}${API.groups}?search=${inputValue}`;
    return sso.fetch(url, { method: 'get'})
      .then(data => {
        const options = data.results.map(node => ({
          label: node.nombre,
          value: node
        }));
        resolve(options);
      })
  }

  loadOptions = inputValue => (
    new Promise(resolve => this.filterOptionsWithDebounce(inputValue, resolve))
  );

  loadOptionsGroup = inputValue => {
    const {getData: getGrupos} = this.props;
    getGrupos({
      url: `${BASE_URL}${API.groups}`,
      type: GET_GRUPOS,
    });
    return new Promise(resolve => this.filterOptionsGroupWithDebounce(inputValue, resolve))
  }

  handleComunas(option) {
    const { getData: getComunas } = this.props;
    getComunas({
      url: `${BASE_URL}${API.comunas}?provincia__region__id=${option.value.id}`,
      type: GET_COMUNAS,
    });
  }

  handleSubmit(values) {
    const { match, patchData: saveClient } = this.props;
    const { id } = match.params;
    saveClient({
      url: `${BASE_URL}${API.clients}${id}/`,
      type: SAVE_CLIENT,
      config: values,
    });
  }

  render() {
    const { initialActividades } = this.state;
    const { clients } = this.props;
    return (
      <PageContainer
        breadcrumbs={[
          { name: 'ORSAN', url: '/clients' },
          { name: 'Editar Cliente' },
        ]}
      >
        {clients.clientDetail
          ? (
            <ClientEditForm
              getComunas={this.handleComunas}
              initial_actividades={initialActividades}
              loadOptions={this.loadOptions}
              loadOptionsGroup={this.loadOptionsGroup}
              onSubmit={this.handleSubmit}
            />
          ) : <div />}
      </PageContainer>
    );
  }
}

const mapStateToProps = state => {
  return { clients: state.clients };
};

const ClientEditView = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ClientEdit);

ClientEdit.propTypes = {
  cleanClient: PropTypes.func.isRequired,
  clients: PropTypes.shape({
    actividades: PropTypes.object,
    clientDetail: PropTypes.object,
    comunas: PropTypes.array,
    error: PropTypes.object,
    listData: PropTypes.object,
    organization_type: PropTypes.array,
    regiones: PropTypes.arrayOf(PropTypes.object),
    saveErrorMessage: PropTypes.string,
    savedClient: PropTypes.object,
    searchedClient: PropTypes.object,
    successClient: PropTypes.bool,
  }).isRequired,
  getData: PropTypes.func.isRequired,
  history: PropTypes.shape({
    length: PropTypes.number,
  }).isRequired,
  match: PropTypes.shape({
    path: PropTypes.string
  }).isRequired,
  patchData: PropTypes.func.isRequired,
};

export default ClientEditView;
