function isValid(rut, body, dv) {
  let dvSum = 0;
  let multiple = 2;
  for (let i = 1; i <= body.length; i += 1) {
    const index = multiple * rut.charAt(body.length - i);
    dvSum += index;
    if (multiple < 7) {
      multiple += 1;
    } else {
      multiple = 2;
    }
  }
  const dvExpected = 11 - (dvSum % 11);

  if (dvExpected === parseInt(dv, 10)) {
    return true;
  }
  if (dvExpected === 10 && dv === 'K') {
    return true;
  }
  return dvExpected === 11 && dv === '0';

}

export default function validateRut(rutNoFormat) {
  if (!rutNoFormat) {
    return { formatRut: '', isValid: false};
  }
  const rut = rutNoFormat.replace(/[^\dk]/gi, '');
  const body = rut.slice(0, -1);
  const dv = rut.slice(-1).toUpperCase();
  const formatRut = `${body.replace(
    /(?!^)(\d{3})(?=(?:\d{3})*$)/g,
    '.$1',
  )}-${dv}`;
  return { formatRut, isValid: isValid(rut, body, dv) };
}
