import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import BaseModal from './BaseModal';
import SafeBtn from './SafeBtn';


const WarningModal = ({
  isOpen,
  toggle,
  handleOk,
  loading,
  okBtnText,
  confirmMsg,
  errorMsg,
  handleClearError,
}) => (
  <div>
    <BaseModal
      handleModal={() => {}}
      modalOpen={isOpen}
      type="modal-warning warning-container"
    >
      <div className="warning-section text">
        <div className="icon-container">
          <FontAwesomeIcon icon={faExclamationTriangle} />
        </div>
        <div>
          {errorMsg || confirmMsg}
        </div>
      </div>
      <div className="button-container warning-section">
        {
          errorMsg
          ? (
            <div>
              <Button
                className="warning-accept"
                onClick={handleClearError}
              >
                Aceptar
              </Button>
            </div>
          )
          : (
            <>
              <div>
                <Button
                  className="warning-cancel"
                  disabled={loading}
                  onClick={toggle}
                >
                  Cancelar
                </Button>
              </div>
              <div>
                <SafeBtn
                  className="warning-accept"
                  loading={loading}
                  onClick={() => handleOk()}
                >
                  {okBtnText}
                </SafeBtn>
              </div>
            </>
          )
        }
      </div>
    </BaseModal>
  </div>
);

WarningModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  handleOk: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  okBtnText: PropTypes.string,
  confirmMsg: PropTypes.string.isRequired,
  errorMsg: PropTypes.string,
  handleClearError: PropTypes.func,
};

WarningModal.defaultProps = {
  okBtnText: 'Aceptar',
  loading: false,
  errorMsg: undefined,
  handleClearError: () => {},
};

export default WarningModal;
