import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';

import PageContainer from 'components/PageContainer';
import * as actions from 'redux-store/actions';
import { GET_CLIENT_LIST, GET_CLIENT_ROLES } from '../../redux-store/constants/action-types';
import { API, BASE_URL, SEARCH_DELAY_MS } from '../../CONST';
import ClientListTable from './ClientListTable';
import isUserHasProfile from '../../helpers/profileValidator';
import { CLIENT_WRITE } from '../../helpers/profilePermission';

const api = API.clients;

class ClientList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
    this.filtering = false;

    this.onFilteredChange = this.onFilteredChange.bind(this);
    this.fetchStrategy = this.fetchStrategy.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.fetchRoles = this.fetchRoles.bind(this);
    this.fetchDataWithDebounce = _.debounce(this.fetchData, SEARCH_DELAY_MS);
  }

  componentDidMount() {
    this.fetchRoles();
  }

  onFilteredChange() {
    this.filtering = true;
  }

  fetchStrategy(tableState) {
    if (this.filtering) {
      return this.fetchDataWithDebounce(tableState);
    }
    return this.fetchData(tableState);
  }

  fetchData(state) {
    const { getData } = this.props;

    this.filtering = false;
    this.setState({
      loading: true,
    });

    let filters = `${BASE_URL}${api}?is_main=0&page=${state.page + 1}&`;
    if (state.filtered.length) {
      state.filtered.forEach((node) => {
        let validFilter = false;
        let filterValue = node.value;

        if (typeof node.value === 'number' && node.value > 0) {
          filterValue = node.value;
          validFilter = true;
        } else if (typeof node.value === 'string' && node.value.length > 2) {
          filterValue = node.value;
          validFilter = true;
        }

        if (validFilter) {
          filters = `${filters}${node.id}=${filterValue}&`;
        }
      });
    }

    getData({
      url: filters,
      name: 'clients',
      type: GET_CLIENT_LIST,
    });
    this.setState({ loading: false });
  }

  fetchRoles() {
    const { getData } = this.props;
    this.setState({
      loading: true,
    });

    getData({
      url: `${BASE_URL}${API.roles}`,
      name: 'roles',
      type: GET_CLIENT_ROLES,
    });

    this.setState({ loading: false });
  }

  render() {
    const { loading } = this.state;
    const { clients } = this.props;
    return (
      <PageContainer breadcrumbs={[{ name: 'ORSAN' }, { name: 'LISTA DE CLIENTES' }]}>
        <Col md={{ offset: 11 }}>
          {isUserHasProfile(CLIENT_WRITE) && (
            <Link
              style={{
                width: '100%',
                backgroundColor: '#F98724',
                border: '#F98724',
              }}
              className="btn btn-secondary"
              disabled={false}
              to="/clients/create"
            >
              <FontAwesomeIcon icon={faPlus} style={{ marginRight: '5px' }} />
              Crear
            </Link>
          )}
        </Col>
        <ClientListTable
          count={clients.listData.count}
          data={clients.listData.data}
          loading={loading}
          pages={clients.listData.pages}
          onFetchData={this.fetchStrategy}
          onFilteredChange={this.onFilteredChange}
          roles={clients.roles}
        />
      </PageContainer>
    );
  }
}

const mapStateToProps = (state) => ({ clients: state.clients });

function mapDispatchToProps(dispatch) {
  return {
    getData: (clients) => dispatch(actions.getData(clients)),
  };
}

const ClientListView = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ClientList);

ClientList.propTypes = {
  clients: PropTypes.shape({
    count: PropTypes.number,
    data: PropTypes.arrayOf(PropTypes.object),
    next: PropTypes.string,
    pages: PropTypes.number,
    previous: PropTypes.string,
  }).isRequired,
  getData: PropTypes.func.isRequired,
};
export default ClientListView;
