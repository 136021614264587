import {
  DELETE_DATA_SUCCESS,
  TOKEN_ERROR,
} from 'redux-store/constants/action-types';
import auth from 'helpers/auth';

export function alertModal(payload) {
  return { type: TOKEN_ERROR, payload };
}

export function loginSuccess(payload) {
  return { type: 'LOGIN_SUCCESS', payload };
}

export function cleanUser(payload) {
  return { type: 'CLEAN_USER', payload };
}

export function cleanUserList(payload) {
  return { type: 'CLEAN_USER_LIST', payload };
}

export function cleanClient(payload) {
  return { type: 'CLEAN_CLIENT', payload };
}

const handleError = (payload, props) => {
  return {
    type: `ERROR_${props.type}`,
    payload,
  };
};

const getDataSuccess = (payload, props) => {
  return {
    type: props.type,
    payload,
  };
};

export const getData = object => {
  return (dispatch) => {
    auth.fetch(object.url)
      .then(response => dispatch(getDataSuccess(response, object)))
      .catch(error => {
        if (error.status !== 503) {
          return error.json()
            .then(errorData => dispatch(handleError(errorData, object)))
        }
        return dispatch(handleError(error, object));
      });
  }
};

const postDataSuccess = (payload, props) => {
  return {
    type: props.type,
    payload,
  };
};

export const postData = object => {
  return (dispatch) => {
    auth.fetch(object.url, {
      method: 'POST',
      body: JSON.stringify(object.config),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => dispatch(postDataSuccess(response, object)))
      .catch(error => {
        if (object.onFailedReq) {
          object.onFailedReq();
        }
        if (error.status !== 503) {
         return error.json()
          .then(errorData => dispatch(handleError(errorData, object)))
        }
        return dispatch(handleError(error, object));
      });
  };
};

const patchDataSuccess = (payload, props) => {
  return {
    type: props.type,
    payload,
  };
};

export const patchData = object => {
  return (dispatch) => {
    auth.fetch(object.url, {
      method: 'PATCH',
      body: JSON.stringify(object.config),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => dispatch(patchDataSuccess(response, object)))
      .catch((error) => {
        if (object.onFailedReq) {
          object.onFailedReq();
        }
        error.json()
          .then(errorData => dispatch(handleError(errorData, object)))
      });
  };
};

const putDataSuccess = (payload, props) => {
  return {
    type: props.type,
    payload,
  };
};

export const putData = object => {
  return (dispatch) => {
    auth.fetch(object.url, {
      method: 'PUT',
      body: JSON.stringify(object.config.body),
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then(response => dispatch(putDataSuccess(response, object)))
    .catch(error => error.json()
      .then(errorData => dispatch(handleError(errorData, object)))
    );
  };
};

const uploadFileSuccess = (payload, props) => {
  return {
    type: props.type,
    payload,
  };
};

export const uploadFile = object => {
  return (dispatch) => {
    auth.fetch(object.url, {
      method: 'PUT',
      body: object.config.body,
      headers: {
        'Content-Disposition': `attachment; filename="${encodeURI(object.config.name)}"`
      }
    })
    .then(response => dispatch(uploadFileSuccess(response, object)))
    .catch(error => error.json()
      .then(errorData => dispatch(handleError(errorData, object)))
    );
  };
};

const deleteDataSuccess = response => {
  return {
    type: DELETE_DATA_SUCCESS,
    response,
  };
};

export const deleteData = object => {
  return (dispatch) => {
    auth.fetch(object.url, {
      method: 'DELETE',
      body: '',
      headers: {
        'Content-Type': 'application/json',
      }
    })
      .then(() => dispatch(deleteDataSuccess(object)))
      .catch(error => error.json()
        .then(errorData => dispatch(handleError(errorData, object)))
      );
  };
};
