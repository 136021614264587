import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AsyncSelect from 'react-select/async';
import { Col, Label } from 'reactstrap';

const ClientListFilter = ({
  clientList,
  callback,
  selectedClient,
  loadClientsOptions,
}) => (
  <div className="form-box" style={{ marginBottom: '15px' }}>
    <Col md="6">
      <Label className="form-label" for="client">
        Filtrar por Cliente
      </Label>
      <AsyncSelect
        name="form-select-by-organizations"
        clearable={false}
        removeSelected={false}
        loadOptions={loadClientsOptions}
        defaultOptions={clientList}
        onChange={callback}
        value={selectedClient}
      />
    </Col>
  </div>
);

ClientListFilter.propTypes = {
  clientList: PropTypes.arrayOf(PropTypes.object).isRequired,
  callback: PropTypes.func.isRequired,
  selectedClient: PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string,
  }),
  loadClientsOptions: PropTypes.func.isRequired,
};

ClientListFilter.defaultProps = {
  selectedClient: {label: '', value: 0},
};

const mapStateToProps = state => ({ persons: state.persons });

export default connect(mapStateToProps)(ClientListFilter);
