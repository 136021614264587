import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import WarningAlert from 'components/UI/WarningAlert';
import PageContainer from 'components/PageContainer';
import { cleanUser, getData, postData } from 'redux-store/actions';
import {
  SEARCH_USER,
  SAVE_USER,
  USERS_GET_ALL_PROFILES
} from 'redux-store/constants/action-types';
import { API, BASE_URL } from 'CONST';
import SearchUserFromAD from './SearchUserFromAD';
import CreateUserForm from './CreateUserForm';


class CreateUserFromAD extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loadByUsername: false,
      alertVisible: false,
    };

    this.userExists = this.userExists.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getData({
      url: `${BASE_URL}${API.perfiles}`,
      type: USERS_GET_ALL_PROFILES,
    }));
  }

  componentWillReceiveProps(nextProps) {
    const { dispatch, history } = this.props;
    if (nextProps.users.searchedUser) {
      const userExists =
        Object.entries(nextProps.users.searchedUser).length !== 0;
      this.setState({
        loading: false,
        loadByUsername: userExists,
        alertVisible: !userExists,
        userData: nextProps.users.searchedUser,
      });
    }
    if (nextProps.users.saveErrorMessage) {
      this.setState({
        loading: false,
      });
    }
    if (nextProps.users.successUser) {
      dispatch(cleanUser());
      history.push('/users');
    }
  }

  userExists(username) {
    const { dispatch } = this.props;
    dispatch(postData({
      url: `${BASE_URL}${API.userAD}`,
      type: SEARCH_USER,
      config: { username },
    }));
    this.setState({
      loading: true,
    });
  }

  handleSubmit(values) {
    const { dispatch } = this.props;
    dispatch(postData({
      url: `${BASE_URL}${API.persons}`,
      type: SAVE_USER,
      config: {
        ...values,
        is_coordinator: false
      },
    }));
  }

  render() {
    const { users } = this.props;
    const { loading, loadByUsername, alertVisible, userData } = this.state;
    return (
      <PageContainer
        breadcrumbs={[
          { name: 'ORSAN', url: '/users' },
          { name: 'Crear Usuario' },
        ]}
      >
        <SearchUserFromAD userExists={this.userExists} loading={loading} />
        {alertVisible && !loading ? (
          <WarningAlert text="No se encontró usuario desde Active Directory" />
        ) : (
          ''
        )}
        {users.saveErrorMessage && !loading ? (
          <WarningAlert text={users.saveErrorMessage} />
        ) : (
          ''
        )}
        {loadByUsername && !loading ? (
          <CreateUserForm
            onSubmit={this.handleSubmit}
            userData={userData}
            allProfiles={users.profiles}
          />
        ) : (
          ''
        )}
      </PageContainer>
    );
  }
}

CreateUserFromAD.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.shape({
    length: PropTypes.number,
  }).isRequired,
  users: PropTypes.shape({
    savedUser: PropTypes.object,
    saveErrorMessage: PropTypes.string,
    searchedUser: PropTypes.shape({
      email: PropTypes.string,
      first_name: PropTypes.string,
      job_title: PropTypes.string,
      last_name: PropTypes.string,
      organization_id: PropTypes.number,
      username: PropTypes.string,
    }),
    successUser: PropTypes.bool,
  }).isRequired,
};

const mapStateToProps = state => ({
  users: state.users
});

export default connect(mapStateToProps)(CreateUserFromAD);
