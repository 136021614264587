export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const TOKEN_ERROR = 'TOKEN_ERROR';
export const GET_USER_INFO = 'GET_USER_INFO';
export const GET_USER_DETAIL = 'GET_USER_DETAIL';
export const GET_USER_LIST = 'GET_USER_LIST';
export const SEARCH_USER = 'SEARCH_USER';
export const SAVE_USER = 'SAVE_USER';
export const EDIT_USER = 'EDIT_USER';
export const CLEAN_USER_LIST = 'CLEAN_USER_LIST';
export const CLEAN_USER = 'CLEAN_USER';
export const ACTIVATE_USER = 'ACTIVATE_USER';
export const ERROR_SEARCH_USER = 'ERROR_SEARCH_USER';
export const ERROR_SAVE_USER = 'ERROR_SAVE_USER';
export const USERS_GET_ALL_PROFILES = 'USERS_GET_ALL_PROFILES';

export const GET_USER_BY_CLIENT = 'GET_USER_BY_CLIENT';
export const GET_ORGANIZATION_TYPE = 'GET_ORGANIZATION_TYPE';

export const GET_CLIENT_LIST = 'GET_CLIENT_LIST';
export const GET_CLIENT_ROLES = 'GET_CLIENT_ROLES';
export const GET_CLIENT_LIST_SELECT = 'GET_CLIENT_LIST_SELECT';
export const GET_CLIENT_INFO = 'GET_CLIENT_INFO';
export const GET_CLIENT_DETAIL = 'GET_CLIENT_DETAIL';
export const SAVE_CLIENT = 'SAVE_CLIENT';
export const EDIT_CLIENT = 'EDIT_CLIENT';
export const CLEAN_CLIENT = 'CLEAN_CLIENT';
export const ERROR_SAVE_CLIENT = 'ERROR_SAVE_CLIENT';

export const GET_COMUNAS = 'GET_COMUNAS';
export const GET_COMUNAS_ALIAS = 'GET_COMUNAS_ALIAS';
export const GET_REGIONES = 'GET_REGIONES';
export const GET_ACTIVIDADES = 'GET_ACTIVIDADES';
export const GET_GRUPOS = 'GET_GRUPOS';
export const PUT_DATA_SUCCESS = 'PUT_DATA_SUCCESS';
export const DELETE_DATA_SUCCESS = 'DELETE_DATA_SUCCESS';
export const GET_LIST_CLIENT_ORGANIZATION = 'GET_LIST_CLIENT_ORGANIZATION';
export const VALIDATE_EXISTENT_RUT = 'VALIDATE_EXISTENT_RUT';
