import {
  ACTIVATE_USER,
  CLEAN_USER,
  CLEAN_USER_LIST,
  EDIT_USER,
  ERROR_SAVE_USER,
  ERROR_SEARCH_USER,
  GET_USER_BY_CLIENT,
  GET_USER_DETAIL,
  GET_USER_LIST,
  SAVE_USER,
  SEARCH_USER,
  USERS_GET_ALL_PROFILES,
} from 'redux-store/constants/action-types';

const initialState = {
  list: [],
  listData: {
    data: [],
    pages: 0,
    count: 0,
    previous: null,
    next: null,
  },
  saveErrorMessage: undefined,
  savedUser: undefined,
  searchedUser: undefined,
  successUser: false,
  userDetail: undefined,
  profiles: [],
};

const executeSearchUserSuccess = (state, action) => ({
  ...state,
  saveErrorMessage: undefined,
  searchedUser: action.payload,
});

const executeGetUserSuccess = (state, action) => ({
  ...state,
  saveErrorMessage: undefined,
  userDetail: action.payload,
});

const executeGetUserListSuccess = (state, action) => ({
  ...state,
  listData: {
    count: action.payload.count,
    data: action.payload.results,
    next: action.payload.next,
    pages: Math.ceil(action.payload.count / 20),
    previous: action.payload.previous,
  },
  saveErrorMessage: undefined,
});

const executeSavePersonSuccess = (state, action) => ({
  ...state,
  saveErrorMessage: undefined,
  savedUser: action.payload,
  successUser: true,
});

const executeActivateUserSuccess = (state, action) => {
  const list = state.listData.data;
  const updatedList = [];
  list.map(node => node.id === action.payload.id
    ? updatedList.push(action.payload)
    : updatedList.push(node));
  return {
    ...state,
    listData: {
      ...state.listData,
      data: updatedList,
    },
    saveErrorMessage: undefined,
  };
};

const executeCleanUser = state => ({
  ...state,
  saveErrorMessage: undefined,
  savedUser: undefined,
  successUser: false,
  userDetail: undefined,
});

const executeCleanUserList = state => ({
  ...state,
  list: [],
  listData: {
    data: [],
    count: undefined,
    next: null,
    pages: 0,
    previous: null,
  },
  saveErrorMessage: undefined,
});

const executeEditPersonSuccess = (state, action) => ({
  ...state,
  saveErrorMessage: undefined,
  userDetail: action.payload,
});

const executeError = (state, action) => {
  const { payload } = action;
  const message = payload
    ? (payload.message ||
      'No se pudo completar la acción, por favor inténtelo más tarde')
    : 'Error';
  return {
    ...state,
    saveErrorMessage: message,
  };
};

const getAllProfiles = (state, action) => ({
  ...state,
  profiles: action.payload,
});

function users(state = initialState, action) {
  switch (action.type) {
    case SEARCH_USER:
      return executeSearchUserSuccess(state, action);
    case GET_USER_DETAIL:
      return executeGetUserSuccess(state, action);
    case GET_USER_LIST:
      return executeGetUserListSuccess(state, action);
    case SAVE_USER:
      return executeSavePersonSuccess(state, action);
    case EDIT_USER:
      return executeEditPersonSuccess(state, action);
    case ACTIVATE_USER:
      return executeActivateUserSuccess(state, action);
    case GET_USER_BY_CLIENT:
      return executeGetUserListSuccess(state, action);
    case ERROR_SEARCH_USER:
      return executeError(state, action);
    case CLEAN_USER:
      return executeCleanUser(state, action);
    case CLEAN_USER_LIST:
      return executeCleanUserList(state, action);
    case ERROR_SAVE_USER:
      return executeError(state, action);
    case USERS_GET_ALL_PROFILES:
      return getAllProfiles(state, action);
    default:
      return state;
  }
}

export default users;
