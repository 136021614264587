import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faPowerOff } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';

import { ACTIVATE_USER, GET_CLIENT_LIST_SELECT } from 'redux-store/constants/action-types';
import { API, BASE_URL, SEARCH_DELAY_MS } from 'CONST';
import { cleanUserList, getData, patchData } from 'redux-store/actions';
import PageContainer from 'components/PageContainer';
import { cellOperation, headerOperation } from 'helpers/tables';
import auth from 'helpers/auth';
import FilterSelect from 'components/shared/FilterSelect';
import ClientListFilter from './ClientListFilter';
import ClientUsersTable from './ClientUsersTable';
import isUserHasProfile from '../../helpers/profileValidator';
import { USER_WRITE } from '../../helpers/profilePermission';

function mapDispatchToProps(dispatch) {
  return {
    getData: (clients) => dispatch(getData(clients)),
    cleanUserList: (users) => dispatch(cleanUserList(users)),
    patchData: (users) => dispatch(patchData(users)),
  };
}

class ClientUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedClient: undefined,
    };

    this.handleClientSelection = this.handleClientSelection.bind(this);
    this.onClick = this.onClick.bind(this);
    this.filterClientOptions = this.filterClientOptions.bind(this);
    this.filterClientsOptionsWithDebounce = _.debounce(this.filterClientOptions, SEARCH_DELAY_MS);
    this.loadClientsOptions = this.loadClientsOptions.bind(this);
  }

  componentDidMount() {
    const { cleanUserList: resetStore, getData: getClientList } = this.props;
    getClientList({
      url: `${BASE_URL}${API.clients}?is_main=0`,
      name: 'clients',
      type: GET_CLIENT_LIST_SELECT,
    });
    resetStore();
  }

  onClick(e, instance) {
    const { patchData: activateUser } = this.props;
    e.preventDefault();
    activateUser({
      url: `${BASE_URL}${API.persons}${instance.id}/`,
      config: { is_active: !instance.is_active },
      type: ACTIVATE_USER,
    });
  }

  filterClientOptions = (inputValue, resolve) => {
    const url = `${BASE_URL}${API.clients}?search=${inputValue}`;
    return auth.fetch(url).then((response) => {
      const options = response.results.map((client) => ({
        value: client.id,
        label:
          client.type_id === 1
            ? `${client.rut} - ${client.first_name} ${client.last_name}`
            : `${client.rut} - ${client.nombre_fantasia}`,
      }));
      resolve(options);
    });
  };

  loadClientsOptions(inputValue) {
    return new Promise((resolve) => this.filterClientsOptionsWithDebounce(inputValue, resolve));
  }

  handleClientSelection(client) {
    this.setState({
      selectedClient: client,
    });
  }

  render() {
    const { user, clients, users } = this.props;
    const breadcrumbs = [];
    if (user.is_orsan) {
      breadcrumbs.push({ name: 'CLIENTES' }, { name: 'LISTA DE USUARIOS' });
    } else {
      breadcrumbs.push({ name: 'LISTA DE USUARIOS' });
    }
    const organization = clients.clientList.find((client) => client.value === user.organization);
    const { selectedClient: client } = this.state;
    const selectedClient = user.is_orsan ? client : organization;
    const isDisabledActiveButton = !isUserHasProfile(USER_WRITE) && !user.is_coordinator;

    const columns = [
      {
        Header: headerOperation('Username'),
        id: 'user__username',
        accessor: (i) =>
          cellOperation(
            <Link className="table-link" to={`/client-users/edit/${i.id}`}>
              {i.username}
            </Link>,
          ),
        minWidth: 100,
      },
      {
        Header: headerOperation('Nombre'),
        id: 'user__first_name__icontains',
        accessor: (i) => cellOperation(i.first_name),
        minWidth: 100,
      },
      {
        Header: headerOperation('Apellidos'),
        id: 'user__last_name__icontains',
        accessor: (i) => cellOperation(i.last_name),
        minWidth: 100,
      },
      {
        Header: headerOperation('Cargo'),
        id: 'job_title__icontains',
        accessor: (i) => cellOperation(i.job_title),
        minWidth: 100,
      },
      {
        Header: headerOperation('Coordinador'),
        id: 'is_coordinator',
        accessor: (i) => cellOperation(i.is_coordinator ? 'Sí' : 'No'),
        Filter: ({ filter, onChange }) => (
          <FilterSelect
            onFilterChange={onChange}
            filter={filter}
            options={[{ label: 'Sí', value: 'true' }, { label: 'No', value: 'false' }]}
            defaultOption={{ label: 'Todos', value: '' }}
          />
        ),
        minWidth: 100,
      },
      {
        Header: headerOperation('RUT'),
        id: 'rut__icontains',
        accessor: (i) => cellOperation(i.rut),
        minWidth: 90,
      },
      {
        Header: headerOperation('Teléfono'),
        id: 'phone__icontains',
        accessor: (i) => cellOperation(i.phone),
        minWidth: 100,
      },
      {
        Header: headerOperation('Usuario'),
        id: 'userState',
        sortable: false,
        filterable: false,
        accessor: (i) => cellOperation(i.is_active ? 'Activo' : 'Inactivo'),
        width: 100,
      },
      {
        Header: headerOperation(''),
        id: 'actions',
        sortable: false,
        filterable: false,
        accessor: (i) => (
          <div
            className="table-cell"
            style={{
              paddingRight: '20px',
              textAlign: 'center',
            }}
          >
            <button
              onClick={(e) => this.onClick(e, i)}
              className={`table-link ${i.is_active ? '' : 'disabled'}`}
              type="button"
              disabled={isDisabledActiveButton}
              style={{ cursor: isDisabledActiveButton && 'auto' }}
            >
              <FontAwesomeIcon icon={faPowerOff} />
            </button>
          </div>
        ),
        width: 60,
      },
    ];
    return (
      <PageContainer breadcrumbs={breadcrumbs}>
        {user.is_orsan ? (
          <ClientListFilter
            callback={this.handleClientSelection}
            clientList={clients.clientList}
            loadClientsOptions={this.loadClientsOptions}
            selectedClient={selectedClient}
          />
        ) : (
          <h4 style={{ color: '#315E90', fontSize: '22px', marginLeft: '16px' }}>
            {organization && organization.label}
          </h4>
        )}
        <Col md={{ offset: 11 }}>
          {(isUserHasProfile(USER_WRITE) || user.is_coordinator) && (
            <Link
              style={{
                width: '100%',
                backgroundColor: '#F98724',
                border: '#F98724',
              }}
              className={`btn btn-secondary${selectedClient === undefined ? ' disabled' : ''}`}
              to={selectedClient ? `/client-users/${selectedClient.value}/create` : '/'}
            >
              <FontAwesomeIcon icon={faPlus} style={{ marginRight: '5px' }} />
              Crear
            </Link>
          )}
        </Col>
        <ClientUsersTable
          count={users.listData.count}
          data={users.listData.data}
          pages={users.listData.pages}
          client={selectedClient}
          columns={columns}
          key={selectedClient && selectedClient.value}
        />
      </PageContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    clients: state.clients,
    login: state.login,
    user: state.login.user,
    users: state.users,
  };
};

const ClientUsersView = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ClientUsers);

ClientUsers.propTypes = {
  cleanUserList: PropTypes.func.isRequired,
  clients: PropTypes.shape({
    actividades: PropTypes.object,
    clientDetail: PropTypes.object,
    comunas: PropTypes.array,
    error: PropTypes.object,
    listData: PropTypes.object,
    organization_type: PropTypes.array,
    regiones: PropTypes.arrayOf(PropTypes.object),
    saveErrorMessage: PropTypes.string,
    savedClient: PropTypes.object,
    searchedClient: PropTypes.object,
    successClient: PropTypes.bool,
  }).isRequired,
  getData: PropTypes.func.isRequired,
  login: PropTypes.shape({
    userToken: PropTypes.string,
  }).isRequired,
  patchData: PropTypes.func.isRequired,
  users: PropTypes.shape({
    listData: PropTypes.shape({
      count: PropTypes.number,
      data: PropTypes.arrayOf(PropTypes.object),
      next: PropTypes.string,
      pages: PropTypes.number,
      previous: PropTypes.string,
    }),
  }).isRequired,
  user: PropTypes.shape({
    is_orsan: PropTypes.bool,
    organization: PropTypes.number,
  }).isRequired,
};

export default ClientUsersView;
