import { ROLES } from '../CONST';

export const emailValidate = email => {
  const regEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line
  return regEx.test(email);
};

export const handleRolesLogic = (roles, event, clientRoles) => {
  // Si se elige rol Beneficiario - Venta en Verde.
  if (event && event.action === "select-option" && event.option.value.natural_key === ROLES.beneficiarioVentaVerde) {
    // Revisa si roles ya contiene Asegurado - Venta en Verde.
    const isAsegurado = roles.find(item => item.value.natural_key === ROLES.aseguradoVentaVerde)
    // Si no lo tiene, se agrega rol Asegurado junto a Beneficiario.
    if (!isAsegurado) {
      const rol = clientRoles.find(item => item.value.natural_key === ROLES.aseguradoVentaVerde)
      roles.push(rol)
    }
  }
  // Si se elige rol Asegurado - Venta en Verde.
  else if (event && event.action === "select-option"  && event.option.value.natural_key === ROLES.aseguradoVentaVerde) {
    // Revisa si roles ya contiene Beneficiario - Venta en Verde.
    const isBeneficiario = roles.find(item => item.value.natural_key === ROLES.beneficiarioVentaVerde)
    // Si no lo tiene, se agrega rol Beneficiario junto a Asegurado.
    if (!isBeneficiario) {
      const rol = clientRoles.find(item => item.value.natural_key === ROLES.beneficiarioVentaVerde)
      roles.push(rol)
    }
  }
  // Si se elige rol Afianzado - Venta en Verde.
  else if (event && event.action === "select-option"  && event.option.value.natural_key === ROLES.afianzadoVentaVerde) {
    // Revisa si roles ya contiene Afianzado - Garantía.
    const isAfianzado = roles.find(item => item.value.natural_key === ROLES.afianzadoGarantia)
    // Si no lo tiene, se agrega rol Afianzado - Garantía junto a Afianzado - Venta en Verde.
    if (!isAfianzado) {
      const rol = clientRoles.find(item => item.value.natural_key === ROLES.afianzadoGarantia)
      roles.push(rol)
    }
  }

  let rolesTemp = roles;
  if (roles) {
    // Si se deselecciona rol Beneficiario - Venta en Verde.
    if (event && event.action === "remove-value" && event.removedValue.value.natural_key === ROLES.beneficiarioVentaVerde) {
      // También se deselecciona rol Asegurado - Venta en Verde.
      rolesTemp = roles.filter(item => item.value.natural_key !== ROLES.aseguradoVentaVerde)
    }
    // Si se deselecciona rol Asegurado - Venta en Verde.
    else if (event && event.action === "remove-value" && event.removedValue.value.natural_key === ROLES.aseguradoVentaVerde) {
      // También se deselecciona rol Beneficiario - Venta en Verde.
      rolesTemp = roles.filter(item => item.value.natural_key !== ROLES.beneficiarioVentaVerde)
    }
    // Si se deselecciona rol Afianzado - Garantía.
    else if (event && event.action === "remove-value" && event.removedValue.value.natural_key === ROLES.afianzadoGarantia) {
      // También se deselecciona rol Afianzado - Venta en Verde.
      rolesTemp = roles.filter(item => item.value.natural_key !== ROLES.afianzadoVentaVerde)
    }
  }

  return rolesTemp;
}
